<template>
  <div>
    <div class="crumbs"
         v-cloak>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> {{ title}}文档
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <h3>修改文档</h3>
      <el-form ref="form"
               :model="form"
               label-width="120px">

        <el-form-item label="文档类型:"
                      required>
          <el-radio-group v-model="docData.documentType"
                          @change="changeLabel">
            <el-radio :label="parseInt(1)">PPT</el-radio>
            <el-radio :label="parseInt(2)">Word</el-radio>
            <el-radio :label="parseInt(3)">Excel</el-radio>
            <el-radio :label="parseInt(4)">素材</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="标题:"
                      required>
          <el-input v-model="docData.name"
                    type="textarea"
                    maxlength="50"
                    show-word-limit
                    :rows="2"
                    class="w200"></el-input>
        </el-form-item>

        <el-form-item label="颜色:"
                      required>
          <el-input v-model="docData.colorName"
                    type="textarea"
                    maxlength="50"
                    show-word-limit
                    :rows="2"
                    class="w200"></el-input>
        </el-form-item>

        <el-form-item label="文档类型父类:"
                      required>
          <el-select v-model="docData.parentCategoryId"
                     @change="fatherChange"
                     filterable
                     placeholder="请选择"
                     class="handle-select mr10">
            <el-option :key="0"
                       label="无"
                       :value="parseInt(0)"></el-option>
            <el-option v-for="(item) in  fatherList"
                       :key="item.id"
                       :label="item.name"
                       :value="parseInt(item.id)"></el-option>
          </el-select>
          <span>子类:</span>
          <el-select v-model="docData.categoryId"
                     placeholder="请选择"
                     class="handle-select mr10 ">
            <el-option key="0"
                       label="请选择"
                       :value="parseInt(0)"></el-option>
            <el-option :key="item.id"
                       v-for="(item) in sonList "
                       :label="item.name"
                       :value="parseInt(item.id)"></el-option>

          </el-select>

          <span v-if="docData.documentType == 1 ">同类:</span>
          <el-select v-model="docData.sameCategoryId"
                     v-if="docData.documentType == 1 "
                     placeholder="请选择"
                     class="handle-select mr10 ">
            <el-option key="0"
                       label="请选择"
                       :value="parseInt(0)"></el-option>
            <el-option :key="item.id"
                       v-for="(item) in sameList "
                       :label="item.name"
                       :value="parseInt(item.id)"></el-option>

          </el-select>

        </el-form-item>

        <el-form-item label="封面图:"
                      required>
          <el-image class="pre"
                    v-if="docData.previewImage.url && docData.previewImage.url.length> 1"
                    :src="docData.previewImage.url"
                    :preview-src-list="[docData.previewImage.url]"></el-image>

          <el-upload class="up-btn"
                     :http-request="uploadCover"
                     action="String"
                     :on-preview="handlePreview"
                     :on-remove="handleRemove"
                     :on-success="clearFile"
                     :beforeUpload="beforeRemove"
                     multiple
                     :show-file-list="false"
                     :on-exceed="handleExceed"
                     :file-list="fileList">
            <el-button size="small"
                       type="primary">点击上传</el-button>

          </el-upload>
          <el-dialog v-model="dialogVisible">
            <img width="100%"
                 :src="dialogImageUrl"
                 alt="">
          </el-dialog>
        </el-form-item>
        <el-form-item label="正文图:"
                      required>
          <el-button type="primary"
                     @click="addList">新增</el-button>
          <div class="tips">图片大小不超过1M,按序号排列。</div>
          <div class="tables">
            <el-table :data="docData.images"
                      border
                      class="table"
                      ref="multipleTable"
                      header-cell-class-name="table-header"
                      @selection-change="handleSelectionChange">
              <el-table-column label="图片"
                               width="140"
                               align="center">
                <template #default="scope"
                          align="center">

                  <el-image class="table-td-thumb"
                            :src="scope.row.url"
                            :preview-src-list="[scope.row.url]"></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="sort"
                               width="100"
                               label="排序"
                               align="center">
                <template #default="scope">
                  <el-input v-model.number="scope.row.sort"
                            size="mini"
                            placeholder="请输入内容" />

                </template>
              </el-table-column>

              <el-table-column label="操作"
                               align="center">
                <template #default="scope">
                  <el-button size="small"
                             type="text"
                             icon="el-icon-delete"
                             class="blue"
                             @click="delRow(scope.$index, scope.row)">删除</el-button>
                  <el-upload class="up-btn"
                             :http-request="uploadImgList"
                             action="String"
                             :on-preview="handlePreview"
                             :on-remove="handleRemove"
                             :before-remove="beforeRemove"
                             multiple
                             :show-file-list="false"
                             :on-exceed="handleExceed"
                             :file-list="fileList">
                    <span class="btn-text"
                          @click="editRow(scope.$index, scope.row)">点击上传</span>
                  </el-upload>

                </template>
              </el-table-column>
            </el-table>
          </div>

        </el-form-item>
        <el-form-item label="模板文档:"
                      required>
          <el-upload class="up-btn"
                     :http-request="uploadPPT"
                     action="String"
                     :on-preview="handlePreview"
                     :on-remove="handleRemove"
                     :on-success="clearFile"
                     :beforeUpload="beforeRemovePPT"
                     multiple
                     :show-file-list="false"
                     :on-exceed="handleExceed"
                     :file-list="fileList">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
          <div class="ppt-title"
               v-if="docData.path &&docData.path.length > 0 ">
            <a :href="docData.url"
               :download="pptName || docData.name">{{ getFileName(docData && docData.url)}}</a>
            <el-button type="danger"
                       v-if="  docData && docData.url && docData.url.length > 0 "
                       icon="el-icon-delete"
                       @click="delteFile('url')"
                       size="mini">删除</el-button>
          </div>
          <div class="ppt-title"
               v-if="docData.pathSecondUrl &&docData.pathSecondUrl.length > 0">
            <a :href="docData.pathSecondUrl"
               :download="pptName || docData.name">{{ getFileName(docData&&docData.pathSecondUrl) }}</a>
            <el-button type="danger"
                       v-if="  docData && docData.pathSecondUrl && docData.pathSecondUrl.length > 0 "
                       icon="el-icon-delete"
                       @click="delteFile('pathSecondUrl')"
                       size="mini">删除</el-button>
          </div>
        </el-form-item>
        <el-form-item label="PDF文档:">
          <el-upload class="up-btn"
                     :http-request="upLoadPdf"
                     action="String"
                     :beforeUpload="checkPDF"
                     multiple
                     :show-file-list="false"
                     :on-exceed="handleExceed"
                     :file-list="fileList">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
          <div class="ppt-title"
               v-if="docData.pdfPathUrl && docData.pdfPathUrl.length> 0 && docData.pdfPath && docData.pdfPath.length> 0">
            <a :href="docData.pdfPathUrl"
               target="_blank"
               download="文档"> {{pdfTitle(docData.pdfPathUrl)}}</a>&nbsp;
            <el-button type="danger"
                       icon="el-icon-delete"
                       size="mini"
                       @click="deltePdfUrl">删除</el-button>
          </div>
        </el-form-item>
        <el-form-item label="视频:">
          <el-upload class="up-btn"
                     :http-request="upLoadVideo"
                     action="String"
                     :beforeUpload="checkMP4"
                     multiple
                     :show-file-list="false"
                     :on-exceed="handleExceed"
                     :file-list="fileList">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
          <div class="ppt-title"
               v-if="docData.videoPathUrl && docData.videoPathUrl.length> 0 && docData.videoPath && docData.videoPath.length> 0  ">
            <a :href="docData.videoPathUrl"
               target="_blank"
               download="视频">{{pdfTitle(docData.videoPathUrl)}}</a>&nbsp;
            <el-button type="danger"
                       icon="el-icon-delete"
                       size="mini"
                       @click="deleteMp4Url">删除</el-button>
          </div>
        </el-form-item>
        <el-form-item label="关键字:"
                      required>
          <el-input v-model="docData.tags"
                    maxlength="200"
                    show-word-limit
                    type="textarea"
                    :rows="2"
                    class="w200"></el-input>
        </el-form-item>
        <el-form-item label="SEO描述:">
          <el-input v-model="docData.seoDescription"
                    maxlength="1000"
                    show-word-limit
                    type="textarea"
                    :rows="2"
                    class="w200"></el-input>
        </el-form-item>

        <el-form-item label="是否启用:">
          <el-checkbox v-model="docData.isEnable">启用</el-checkbox>
        </el-form-item>
        <el-form-item label="下载量">
          <el-input v-model.number="docData.downloadInitCount"
                    class="w120"></el-input>
          <span> 实际下载量:</span>
          <span> {{docData.downloadCount}}</span>
          <div>虚拟下载量范围: 1000--2000</div>
        </el-form-item>
        <el-form-item label="收藏量">
          <el-input v-model.number="docData.collectInitCount"
                    class="w120"></el-input>
          <span> 实际收藏量:</span>
          <span> {{ docData.collectCount }}</span>
          <div>虚拟收藏量范围: 1200--2500</div>
        </el-form-item>
        <el-form-item label="页数"
                      required>
          <el-input v-model.number="docData.pageCount"
                    class="w120"></el-input>
        </el-form-item>
        <el-form-item label="作者">
          <el-input v-model.number="docData.author"
                    class="w120"></el-input>
        </el-form-item>
      </el-form>
      <!-- 右侧数据 -->
      <div class="right-box">
        <p class="title">职业标签：</p>
        <el-input v-model="tag"
                  placeholder="请输入职业标签"
                  type="textarea"
                  autosize></el-input>
        <div class="keywords">
          <el-button size="mini"
                     v-for="(item, index) in tagArr"
                     :key="index"
                     @click="chooseTag(item)">{{item}}</el-button>
        </div>
      </div>
      <div class="btns">
        <el-button type="primary"
                   @click="saveData">保存</el-button>
        <el-button @click="closePage">取消</el-button>
        <el-button type="success"
                   @click="newRefresh">立即刷新</el-button>
      </div>

    </div>
  </div>
</template>

<script>
import { getpptTypeList, getDocDesc, getUpload, getUploadPPT, getPptDataSave, getUpLoadPdfVideo, getDocType, getRefreshData } from "../../api/index";
import { tagArr } from "../../assets/js/common";
export default {
  name: "adddoc",
  data () {
    return {

      sonList: [],
      fatherList: [],
      SonId: '',
      pptName: "",// ppt名称
      rowBtn: true,
      activeIndex: 0,//  当前行数
      imgListPath: '',
      imgListUrl: '',// 列表图片
      coverPath: '',
      coverUrl: '', // 封面显示
      docData: {
        sort: 0,
        id: 0,
        name: '',
        tags: '',
        seoDescription: '',
        path: '',
        colorName: "",// 颜色
        categoryId: '',
        isEnable: true,
        collectInitCount: 0, // 初始
        downloadInitCount: 0,
        collectCount: 0, //实际
        downloadCount: 0,
        previewImage: {
          url: ""
        },
        images: [],
        pageCount: "",
        pathSecondUrl: "",
        txt: ""

      },
      title: "修改",
      checked: true,
      query: {
        address: "",
        name: "",
        pageIndex: 1,
        pageSize: 10
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      docId: '',
      formData: new FormData(),
      file: {}, //文件数据  
      fileList: [],
      typeList: [],//  分类
      pdfUrl: "",
      pdfPath: "",
      videoUrl: "",
      videoPath: "",
      pdfPathFlag: false, // 已有pdf修改必须上传pdf
      videoPathFlag: false, //
      tagArr: tagArr,
      tag: '',
      sameList: [],// 同类下拉



    };
  },
  created () {

    let flag = this.$route.query.flag;
    let id = this.$route.query.id;
    this.docId = id;

    if (flag == '0') {
      // 修改
      this.title = "添加";

    } else {
      // 添加
      this.title = "修改";
    }
    this.getDesc();
  },
  mounted () {
    this.getSameCateList();
  },
  methods: {
    // 获取同类下拉
    getSameCateList () {
      let params = {
        name: this.name,
        isEnable: 1,
        pageNum: 1,
        pageSize: 100,
        documentType: 1
      };
      getpptTypeList(params).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.sameList = res.data.result;
        }
      })
    },
    // 删除模板文件
    delteFile (type) {
      if (type === "url") {
        this.docData.url = ""; //用于展示
        this.docData.path = ""; //用于保存
      } else {
        this.docData.pathSecond = ""; //用于保存
        this.docData.pathSecondUrl = "";// 用于展示 
      }

    },
    // 文件名获取
    getFileName (str) {
      if (str && str.length > 0) {
        let s = str.lastIndexOf("/") + 1;
        let n = str.slice(s);
        return n;
      }
      console.log(str);

    },
    // 类型修改
    changeLabel (e) {
      console.log(e);
      this.getLabels(0);
      this.docData.parentCategoryId = "";
      this.docData.categoryId = "";
    },
    // 获取文档分类
    getLabels (id) {
      let params = {
        parentId: id,
        documentType: this.docData.documentType
      };
      getDocType(params).then(res => {
        console.log(res);
        if (id === 0) {
          this.fatherList = res.data;
        } else {
          this.sonList = res.data;
        }
      })
    },
    deltePdfUrl () {
      this.docData.pdfPathUrl = "";
      this.docData.pdfPath = "";
    },
    deleteMp4Url () {
      this.docData.videoPathUrl = "";
      this.docData.videoPath = "";
    },
    pdfTitle (val) {
      if (val.length > 0) {
        let s = val.lastIndexOf('/') + 1;
        return val.substring(s)
      }
    },

    closePage () {
      let list = this.$store.state.tagsList;
      let path = this.$route.name;
      for (var i = 0; i < list.length; i++) {
        if (list[i].name == path) {
          this.num = i;
        }
      }
      this.$store.commit("delTagsItem", { index: this.num });
      this.$router.push({ path: "/table" });
    },
    delRow (i) {
      this.docData.images.splice(i, 1);
    },
    // 改变父类
    fatherChange (v) {
      console.log(v);
      this.sonList = [];
      this.docData.categoryId = "";
      this.getLabels(v);
    },
    // 编辑
    editRow (index, row) {
      row.isEdit = true;
      this.rowBtn = true;
      this.activeIndex = index;
    },
    handleRemove (file, fileList) {
      console.log(file, fileList);
    },
    changeFile (file, fileList) {
      console.log(file, fileList);
    },
    handlePreview (file) {
      console.log(file);
    },
    // 清除
    clearFile () {
      this.fileList = [];
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 上传图片校验
    beforeRemove (file, fileList) {
      console.log(fileList);
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg' || testmsg === 'JPG' || testmsg === 'png' || testmsg === 'PNG';
      const isLt50M = file.size / 1024 / 1024 < 1;
      if (!extension) {
        this.$message({
          message: '上传文件只能是jpg或者png格式!',
          type: 'error'
        });
        return false;//必须加上return false; 才能阻止
      }
      console.log(file)
      if (!isLt50M) {
        this.$message({
          message: '上传文件大小不能超过 1MB!',
          type: 'error'
        });
        return false;
      }
      return extension && isLt50M;
    },
    // 校验ppt
    // 校验ppt
    beforeRemovePPT (file, fileList) {

      if (this.docData.categoryId == "") {
        this.$message({
          message: '请选择子分类',
          type: 'error'
        });
        return false;
      }
      console.log(fileList);
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
      let extension = false;
      this.showMessage = "";
      if (this.docData.documentType === 1) {
        extension = testmsg === 'pptx' || testmsg === 'ppt';
        this.showMessage = "上传文件只能是PPT或者PPTX格式!";
      } else if (this.docData.documentType === 2) {
        extension = testmsg === 'docx' || testmsg === 'docm' || testmsg === 'doc';
        this.showMessage = "上传文件只能是doc或者docx格式!";
      } else if (this.docData.documentType === 3) {
        extension = testmsg === 'xlsx' || testmsg === 'xls' || testmsg === 'csv';
        this.showMessage = "上传文件只能是xlsx或者xls格式!";
      } else if (this.docData.documentType === 4) {
        extension = testmsg === 'ai' || testmsg === 'psd' || testmsg === 'png' || testmsg === 'PNG';
        this.showMessage = "上传文件只能是ai或者psd或者png格式!";
      }

      const isLt50M = file.size / 1024 / 1024 < 200;
      this.docData.fileSize = (file.size / 1024 / 1024).toFixed(2) + 'MB';
      if (!extension) {
        this.$message({
          message: this.showMessage,
          type: 'error'
        });
        return false;//必须加上return false; 才能阻止
      }
      console.log(file)
      if (!isLt50M) {
        this.$message({
          message: '上传文件大小不能超过200MB!',
          type: 'error'
        });
        return false;
      }
      return extension && isLt50M;
    },
    // 视频
    checkMP4 (file, fileList) {
      console.log(file, fileList);
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg == 'mp4' || testmsg == 'MP4';
      const isLt50M = file.size / 1024 / 1024 < 200;
      if (!extension) {
        this.$message({
          message: '上传文件只能是MP4格式!',
          type: 'error'
        });
        return false;//必须加上return false; 才能阻止
      }
      console.log(file)
      if (!isLt50M) {
        this.$message({
          message: '上传文件大小不能超过200MB!',
          type: 'error'
        });
        return false;
      }

      return extension && isLt50M;
    },
    // PDF
    checkPDF (file, fileList) {
      console.log(file, fileList);
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg == 'pdf' || testmsg == 'PDF';
      const isLt50M = file.size / 1024 / 1024 < 100;
      if (!extension) {
        this.$message({
          message: '上传文件只能是PDF格式!',
          type: 'error'
        });
        return false;//必须加上return false; 才能阻止
      }
      console.log(file)
      if (!isLt50M) {
        this.$message({
          message: '上传文件大小不能超过100MB!',
          type: 'error'
        });
        return false;
      }
      return extension && isLt50M;

    },
    // 上传封面
    uploadCover (params) {
      const file = params.file
      var formdata = new FormData();
      formdata.append("file", file);
      getUpload(formdata).then(res => {
        this.coverPath = res.data.path;
        this.coverUrl = res.data.url;
        this.docData.previewImage.url = this.coverUrl;// 用于显示
        this.docData.previewImage.path = this.coverPath;// 用于保存
        this.docData.previewImage.name = file.name;// 名称
      })
    },
    // 上传列表
    uploadImgList (params) {
      const file = params.file
      var formdata = new FormData();
      formdata.append("file", file);
      getUpload(formdata).then(res => {
        this.imgListPath = res.data.path;
        this.imgListUrl = res.data.url;
        this.docData.images[this.activeIndex].name = file.name;
        this.docData.images[this.activeIndex].url = this.imgListUrl; //用于展示
        this.docData.images[this.activeIndex].path = this.imgListPath; //用于保存
        this.rowBtn = false;
      });
      if (this.imgListPath == '') {
        this.rowBtn = false;
      }
    },
    // 上传ppt
    uploadPPT (params) {
      const file = params.file
      var formdata = new FormData();
      formdata.append("file", file);
      getUploadPPT(Number(this.docData.categoryId || this.docData.parentCategoryId), formdata).then(res => {
        this.pptPath = res.data.path;
        this.pptUrl = res.data.url;
        if (this.docData.documentType === 4) {
          if (this.pptPath.indexOf("png") > -1 || this.pptPath.indexOf("PNG") > -1) {
            // 图片
            this.docData.pathSecond = this.pptPath;
            this.docData.pathSecondUrl = this.pptUrl;
          } else {
            // 源文件
            this.docData.url = this.pptUrl; //用于展示
            this.docData.path = this.pptPath; //用于保存
          }
        } else {
          this.docData.url = this.pptUrl; //用于展示
          this.docData.path = this.pptPath; //用于保存
        }

        // if (this.docData.path.length === 0) {
        //   this.docData.url = this.pptUrl; //用于展示
        //   this.docData.path = this.pptPath; //用于保存
        // } else if (this.docData.documentType === 4) {
        //   this.docData.pathSecond = this.pptPath; //用于保存
        //   this.docData.pathSecondUrl = this.pptUrl;// 用于展示 
        // } else {
        //   this.docData.url = this.pptUrl; //用于展示
        //   this.docData.path = this.pptPath; //用于保存
        // }
        this.pptName = file.name;
      });
    },
    // 上传pdf
    upLoadPdf (params) {
      const file = params.file
      var formdata = new FormData();
      formdata.append("file", file);
      getUpLoadPdfVideo(3, formdata).then(res => {
        console.log(res);
        this.docData.pdfPathUrl = this.pdfUrl = res.data.url;  // 显示
        this.docData.pdfPath = this.pdfPath = res.data.path; // 保存
        this.$message({
          message: "PDF上传成功",
          type: 'success'
        });
      });
    },
    // 上传视频
    upLoadVideo (params) {

      const tips = this.$message({
        message: "视频上传中。。。",
        type: 'success',
        duration: 0
      });

      const file = params.file
      var formdata = new FormData();
      formdata.append("file", file);
      getUpLoadPdfVideo(4, formdata).then(res => {
        console.log(res);
        tips.close();
        this.docData.videoPathUrl = this.videoUrl = res.data.url;  // 显示
        this.docData.videoPath = this.videoPath = res.data.path;// 保存
        this.$message({
          message: "视频上传成功",
          type: 'success'
        });

      });
    },
    // 保存数据
    saveData () {
      if (this.title == "添加") {
        this.docData.id = 0;
      }
      // 标题校验
      if (this.docData.name.length <= 0) {
        this.$message({
          message: "请输入标题",
          type: 'error'
        });
        return false;
      }
      // 颜色校验
      if (this.docData.colorName.length <= 0) {
        this.$message({
          message: "请输入颜色",
          type: 'error'
        });
        return false;
      }
      // 文档类型校验
      this.docData.sort = this.docData.categoryId != '' ? 1 : 0;// 层级
      // 如果子类为0 ,那么就是只有父类，categoryId == 0，没有子类。

      if (this.docData.parentCategoryId == '' || this.docData.parentCategoryId == undefined) {
        this.$message({
          message: "请选择子类",
          type: 'error'
        });
        return false;
      }
      if (this.docData.categoryId == '' || this.docData.categoryId == undefined) {
        this.$message({
          message: "请选择子类",
          type: 'error'
        });
        return false;
      }
      if (this.docData.documentType == 1) {
        if (this.docData.sameCategoryId == '' || this.docData.sameCategoryId == undefined) {
          this.$message({
            message: "请选择同类",
            type: 'error'
          });
          return false;
        }
      }




      // 封面校验
      if (this.docData.previewImage.url && this.docData.previewImage.url.length <= 0) {
        this.$message({
          message: "请上传封面",
          type: 'error'
        });
        return false;
      }
      // 正文图片校验
      // if (this.docData.images.length <= 0) {
      //   this.$message({
      //     message: "请上传正文图片",
      //     type: 'error'
      //   });
      //   return false;
      // } else {
      //   let filterList = this.docData.images.filter(item => {
      //     return item.url === '' || item.path.length <= 0;
      //   })
      //   console.log(filterList, "filterListfilterListfilterList");
      //   if (filterList.length > 0) {
      //     this.$message({
      //       message: "请上传文档正文的图片或者排序",
      //       type: 'error'
      //     });
      //     return false;
      //   }
      //   let nList = this.docData.images.filter(item => {
      //     return item.sort === '';
      //   })
      //   console.log(nList, "nListnListnList");
      //   if (nList.length > 0) {
      //     this.$message({
      //       message: "请输入排序",
      //       type: 'error'
      //     });
      //     return false;
      //   }
      // }
      // ppt模板
      // ppt模板

      if (this.docData.documentType == 1) {
        this.txt = "PPT";
      } else if (this.docData.documentType == 2) {
        this.txt = "Word";
      } else if (this.docData.documentType == 3) {
        this.txt = "Excel";
      } else if (this.docData.documentType == 4) {
        this.txt = "素材";
      }
      if (this.docData.path.length <= 0 && this.docData.documentType != 4) {
        this.$message({
          message: "请上传" + this.txt + "文件",
          type: 'error'
        });
        return false;
      }
      // 关键字校验
      if (this.docData.tags.length <= 0) {
        this.$message({
          message: "请填写关键字",
          type: 'error'
        });
        return false;
      }
      if (this.docData.pageCount <= 0 || this.docData.pageCount == "") {
        this.$message({
          message: "请填写文档页数,不能为0",
          type: 'error'
        });
        return false;
      }

      this.docData.categoryId = this.docData.categoryId == 0 ? this.docData.parentCategoryId : this.docData.categoryId;
      if (this.docData.categoryId == '' && this.docData.categoryId != 0 || this.docData.categoryId == undefined) {
        this.$message({
          message: "请选择类型",
          type: 'error'
        });
        return false;
      }
      // 取消pdf限制
      // if (this.pdfPathFlag && this.docData.pdfPath.length <= 0) {
      //   this.$message({
      //     message: "请上传pdf文档",
      //     type: 'error'
      //   });
      //   return false;
      // }
      delete this.docData.parentCategoryId;
      delete this.docData.pdfPathUrl;
      delete this.docData.videoPathUrl;

      // if (this.tag.length <= 0) {
      //   this.$message({
      //     message: "请选择职业标签",
      //     type: 'error'
      //   });
      //   return false;
      // }
      this.docData.categoryExpression = this.tag;
      getPptDataSave(this.docData).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.closePage();
          this.$message({
            message: '保存成功!',
            type: 'success'
          })
        } else {
          this.$message({
            message: '保存失败!',
            type: 'error'
          })
        }

      })

    },
    // 立即刷新
    newRefresh () {
      getRefreshData().then(res => {
        console.log(res);
        this.$message.success("刷新成功！")
      })
    },
    // 新增列表
    addList () {
      if (this.docData.images.length == 60) {
        this.$message({
          message: "最多60个图片",
          type: 'error'
        });
        return false;
      }
      this.docData.images.push({
        url: '',
        sort: '',
        isEdit: false
      })
    },
    getDesc () {
      if (this.docId != undefined) {
        getDocDesc(this.docId,).then(res => {
          console.log(res);
          this.docData = res.data;

          this.tag = this.docData.categoryExpression;
          this.pdfPathFlag = this.docData.pdfPath.length > 0; // pdf修改标志
          this.videoPathFlag = this.docData.videoPath.length > 0; //  视频修改标志
          this.getLabels(0);// 获取父类
          this.getLabels(this.docData.parentCategoryId);
          if (this.docData.pathSecond) {
            this.docData.pathSecondUrl = "https://pptres.qyzhuomian.com" + this.docData.pathSecond;
          }
        })
      }

    },
    chooseTag (item) {
      this.tag = item;
    },
  }
}
</script>

<style scoped >
.container {
  min-width: 1000px;
  position: relative;
  top: 0;
  left: 0;
}
.container .right-box {
  position: absolute;
  top: 120px;
  right: 60px;
  width: 400px;
  border: 1px solid #eee;
  padding: 6px;
  box-sizing: border-box;
}

.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #fff;
  margin: 10px;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.w80 {
  width: 80px;
}
.ml10 {
  margin-left: 10px;
}
.btns {
  margin-bottom: 10px;
}
.w120 {
  width: 120px;
}
.w200 {
  width: 400px;
}
.btns {
  padding-left: 200px;
}
.tables {
  width: 500px;
  padding-top: 10px;
}
.tips {
  font-size: 12px;
  color: #999;
  line-height: 20px;
}
.pre {
  display: inline-block;
  width: 300px;
  height: 450px;
}
.tables .up-btn {
  display: inline-block;
  vertical-align: middle;
}
.tables .up-btn .btn-text {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #fff;
  line-height: 32px;
}
.up-btn >>> .el-upload {
  width: 80px;
  height: 32px;
  border: 0 !important;
  background-color: #409eff;
  line-height: 20px;
}
.up-btn >>> .el-button--small {
  width: 80px;
  height: 32px;
  border: 0 !important;
  background-color: #409eff;
}
.blue {
  color: #409eff;
  margin-right: 20px;
}
.keywords {
  padding-top: 5px;
  padding-bottom: 10px;
}
.keywords button {
  margin-left: 0;
  margin-bottom: 5px;
  margin-right: 10px;
}
.title {
  padding-bottom: 10px;
  color: #606266;
  font-weight: bold;
}
</style>
