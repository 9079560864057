<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> ppt分类管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <span>类型名称:</span>
        <el-input v-model="name"
                  placeholder="请输入名称或ID"
                  class="handle-input mr10 w100"></el-input>
        <span> 文档类型:</span>
        <el-select v-model="documentType"
                   @change="changeDocType"
                   placeholder="请选择"
                   class="handle-select mr10 w80">
          <el-option key="0"
                     label="全部"
                     :value="parseInt(0)"></el-option>
          <el-option key="1"
                     label="PPT"
                     :value="parseInt(1)"></el-option>
          <el-option key="2"
                     label="Word"
                     :value="parseInt(2)"></el-option>
          <el-option key="3"
                     label="Excel"
                     :value="parseInt(3)"></el-option>
          <el-option key="4"
                     label="素材"
                     :value="parseInt(4)"></el-option>
        </el-select>
        <span> 是否启用:</span>
        <el-select v-model="isEnable"
                   placeholder="请选择"
                   class="handle-select mr10 w80">
          <el-option key=""
                     label="请选择"
                     value=""></el-option>

          <el-option key="1"
                     label="启用"
                     value="1"></el-option>
          <el-option key="0"
                     label="禁用"
                     value="0"></el-option>
        </el-select>

        <span> 父类:</span>
        <el-select v-model="parentId"
                   placeholder="请选择"
                   class="handle-select mr10 w80">
          <el-option :key="item.id"
                     v-for="(item) in fatherList "
                     :label="item.name"
                     :value="item.id"></el-option>
        </el-select>
        <el-button type="primary ml10"
                   icon="el-icon-search"
                   @click="handleSearch">搜索</el-button>

      </div>
      <div class="btns">
        <el-button type="primary ml10"
                   icon="el-icon-plus"
                   @click="addCate(1)">添加分类</el-button>
        <!-- <el-button type="primary ml10"
                   icon="el-icon-delete"
                   @click="changeStatus(-1)">批量删除</el-button> -->
        <el-button type="primary ml10"
                   icon="el-icon-circle-check"
                   @click="changeStatus(1)">批量启用</el-button>
        <el-button type="primary ml10"
                   icon="el-icon-circle-close"
                   @click="changeStatus(0)">批量禁用</el-button>
        <!-- <el-button type="primary ml10"
                   icon="el-icon-download"
                   @click="handleSearch">导出</el-button> -->

      </div>
      <el-table :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection"
                         width="55"
                         align="center"></el-table-column>
        <el-table-column prop="id"
                         label="类型Id"
                         width="120"
                         align="center"></el-table-column>
        <el-table-column prop="name"
                         label="名称"
                         align="center"></el-table-column>
        <el-table-column prop="name"
                         width="120"
                         label="文档类型"
                         align="center">
          <template #default="scope">
            <span v-if="scope.row.documentType==1">PPT</span>
            <span v-if="scope.row.documentType==2">Word</span>
            <span v-if="scope.row.documentType==3">Excel</span>
            <span v-if="scope.row.documentType==4">素材</span>
          </template>
        </el-table-column>
        <el-table-column prop="keywords"
                         label="SEO关键字"
                         align="center"></el-table-column>
        <el-table-column prop="parentName"
                         label="父类名称"
                         align="center"></el-table-column>

        <el-table-column prop="sort"
                         label="排序"
                         align="center"></el-table-column>
        <el-table-column label="操作"
                         align="center">
          <template #default="scope">
            <el-button type="text"
                       icon="el-icon-edit"
                       @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button type="text"
                       icon="el-icon-error"
                       v-if="scope.row.isEnable"
                       class="red"
                       @click="DisableRow(scope.$index, scope.row)">禁用</el-button>
            <el-button type="text"
                       v-else
                       icon="el-icon-success"
                       @click="enableRow(scope.$index, scope.row)">启用</el-button>
            <!-- 
            <el-button type="text"
                       icon="el-icon-delete"
                       class="red"
                       @click="delRow(scope.$index, scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination background
                       layout="total, prev, pager, next"
                       :current-page="pageNum"
                       :page-size="10"
                       :total="totalSize"
                       @current-change="handlePageChange"></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog :title="dialogTitle"
               v-model="editVisible"
               width="30%">
      <el-form ref="form"
               :model="rowData"
               label-width="100px">
        <el-form-item label="文档类型:">
          <el-select v-model="rowData.documentType"
                     @change="changeDocType"
                     placeholder="请选择"
                     class="handle-select mr10 w80">
            <el-option key="1"
                       label="PPT"
                       :value="parseInt(1)"></el-option>
            <el-option key="2"
                       label="Word"
                       :value="parseInt(2)"></el-option>
            <el-option key="3"
                       label="Excel"
                       :value="parseInt(3)"></el-option>
            <el-option key="4"
                       label="素材"
                       :value="parseInt(4)"></el-option>
          </el-select>

        </el-form-item>
        <el-form-item label="名称:">
          <el-input v-model="rowData.name"
                    maxlength="10"
                    placeholder="请输入分类名，支持中英文"></el-input>
        </el-form-item>

        <el-form-item label="父节点:">
          <el-select v-model="rowData.parentId"
                     placeholder="请选择"
                     class="handle-select mr10">
            <el-option key="-1"
                       v-if="rowData.parentId == -1"
                       label="无"
                       :value="parseInt(-1)"></el-option>
            <el-option key="0"
                       v-if="rowData.parentId == 0"
                       label="无"
                       :value="parseInt(0)"></el-option>
            <el-option :key="item.id"
                       v-for="(item) in fatherList "
                       :label="item.name"
                       :value="parseInt(item.id)"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序:">
          <el-input v-model.number="rowData.sort"
                    type="number"
                    placeholder="数字越小，越靠前"></el-input>
        </el-form-item>
        <el-form-item label="SEO关键字:">
          <el-input v-model="rowData.keywords"
                    type="textarea"
                    :rows="3"
                    maxlength="100"
                    placeholder="请输入关键字，英文逗号隔开"></el-input>
        </el-form-item>
        <el-form-item label="描述:">
          <el-input v-model.trim="rowData.description"
                    placeholder="请输入描述"
                    maxlength="20"></el-input>
        </el-form-item>
        <div class="check">
          <el-form-item label="是否启用">
            <el-checkbox v-model="rowData.isEnable">启用</el-checkbox>
          </el-form-item>
          <el-form-item label="是否热门推荐"
                        v-if=" rowData.parentId != 0 ">
            <el-checkbox v-model="rowData.isRecommend">推荐</el-checkbox>
          </el-form-item>
        </div>
        <el-form-item label="推荐排序:"
                      v-if=" rowData.parentId != 0 ">
          <el-input v-model.trim="rowData.recoSort"
                    type="number"
                    placeholder="请输入排序"
                    maxlength="20"></el-input>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="saveEdit">保 存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getDocTypes, getFatherCategory, getChangeCategory, getCategoryStatus, getDocType, getOneCateData } from "../../api/index";
export default {
  name: "basetable",
  data () {
    return {

      pageNum: 1, // 当前分页
      pageSize: 10, //  
      totalSize: 0,
      name: '',
      isEnable: "",
      category: '',// 分类
      rowData: null,// 行数据
      tableData: [],
      editVisible: false,
      form: {},
      idx: -1,
      id: -1,
      tipsTxt: "",
      ids: [], // 选中id
      dialogTitle: "",
      fatherList: [],
      parentId: '',
      documentType: 0, // 0：全部，1：PPT模板，2：Word模板，3：Excel表格，4：设计素材
    };
  },
  created () {
    this.getData();
    this.getFatherList();
  },
  methods: {
    changeDocType (e) {
      console.log(e);
      this.getLabels(e);
    },
    // 获取子分类
    getSonCate (id) {
      let params = {
        id
      };
      getOneCateData(params).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.rowData = res.data;
          this.editVisible = true;
        }
      })
    },
    // 获取文档分类
    getLabels (id) {
      let params = {
        parentId: 0,
        documentType: this.rowData && this.rowData.documentType || id
      };
      getDocType(params).then(res => {
        this.fatherList = res.data;
      })
    },
    getFatherList () {
      getFatherCategory().then(res => {
        console.log(res);
        // this.fatherList = res.data;
      })
    },
    //禁用
    DisableRow (i, row) {

      this.$confirm('将禁用该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.ids = [];
        this.ids.push(row.id);
        this.changeStatus(0);

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消禁用'
        });
      });
    },
    // 启用
    enableRow (i, row) {
      this.$confirm('将启用该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.ids = [];
        this.ids.push(row.id);
        this.changeStatus(1);


      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消启用'
        });
      });
    },
    //删除
    delRow () {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 获取 数据
    getData () {

      let params = {
        name: this.name,
        isEnable: this.isEnable == '' ? '' : Number(this.isEnable),
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        parentId: this.parentId != '' ? Number(this.parentId) : '',
        documentType: Number(this.documentType)
      };
      getDocTypes(params).then(res => {
        console.log(res);
        this.tableData = res.data.result;
        this.totalSize = res.data.totalSize;// 总数

      })
    },
    //搜索按钮
    handleSearch () {
      console.log(this.isEnable, "isEnableisEnableisEnable");
      this.pageNum = 1;
      this.getData();
    },

    // 多选操作
    handleSelectionChange (val) {
      let idList = val.map(item => {
        return item.id
      });
      this.ids = idList;
      console.log(idList, "multipleSelectionmultipleSelectionmultipleSelection");
    },
    // 添加分类
    addCate (flag) {

      this.rowData = {
        id: "",
        name: "",
        parentId: -1,
        sort: "",
        level: "",
        isEnable: true,
        keywords: "",
        documentType: 1,
        description: "",
        isRecommend: false,
        recoSort: 0,

      }
      if (flag == 1) {
        this.dialogTitle = "添加";
        this.getLabels(1);
      }
      this.editVisible = true;
    },
    checkIds () {
      if (this.ids.length <= 0) {
        this.$message({
          message: '未选中数据',
          type: 'error'
        })
        return false;
      }
    },
    // 批量启用禁用删除 ：-1删除（暂不支持），1启用，0禁用
    changeStatus (val) {
      this.checkIds();
      if (this.ids.length <= 0) {
        return false;
      }
      let ids = this.ids;
      let params = {
        batchType: val,
        ids: ids
      };

      if (val == 1) {
        this.tipsTxt = "启用";
      } else if (val == 0) {
        this.tipsTxt = "禁用";
      }
      getCategoryStatus(params).then(res => {
        if (res.data > 0) {
          this.$message({
            message: this.tipsTxt + res.message,
            type: "success"
          });
          this.getData();
        } else {
          this.$message({
            message: "该数据已经" + this.tipsTxt,
            type: "error"
          })
        }

      })
    },

    // 编辑操作
    handleEdit (index, row) {
      console.log(row, 11);

      this.getSonCate(row.id);


      // this.getFatherList();
      this.getLabels(row.id);
      this.dialogTitle = "修改";
      this.rowData = row;
      this.idx = index;
      this.form = row;
      this.editVisible = true;
      // return false;
    },
    // 保存编辑
    saveEdit () {
      this.rowData.keywords = this.rowData.keywords.replace(/，/ig, ',');
      let list = this.rowData.keywords.split(",");
      let len = 0;
      list.map(item => {
        len += item.length;
      })
      console.log(len);
      if (len > 100) {
        this.$notify.error({
          title: "提示",
          message: "SEO关键字数量不超过100个"
        });
        return;
      }

      let params = {
        id: this.rowData.id,
        name: this.rowData.name,
        parentId: this.rowData.parentId,
        keywords: this.rowData.keywords,
        documentType: parseInt(this.rowData.documentType),
        // level: this.rowData.level,
        sort: this.rowData.sort,
        isEnable: this.rowData.isEnable ? 1 : 0,
        description: this.rowData.description,
        isRecommend: this.rowData.isRecommend,
        recoSort: this.rowData.recoSort,
      };
      if (this.rowData.name == '') {
        this.$message({
          message: "请输入名称",
          type: "error"
        });
        return false
      }
      if (this.rowData.sort == '') {
        this.$message({
          message: "请输入排序",
          type: "error"
        });
        return false
      }




      getChangeCategory(params).then(res => {
        this.getData();
        if (res.code != 0) {
          this.$message({
            message: res.message,
            type: "error"
          })

        } else {
          this.editVisible = false;
          this.$message({
            message: res.message,
            type: "success"
          })
        }
      })

    },
    // 分页导航
    handlePageChange (val) {
      this.pageNum = val;
      this.getData();
    }
  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.w80 {
  width: 98px;
}
.ml10 {
  margin-left: 10px;
}
.btns {
  margin-bottom: 10px;
}
.check {
  display: flex;
}
</style>
