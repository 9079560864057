// 范文列表

<template>
  <div class="model-list">
    <div class="model-form">
      <el-form :inline="true"
               :model="formData"
               class="demo-form-inline">
        <el-form-item label="标题:">
          <el-input v-model="formData.title"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="审核状态:">
          <el-select v-model="formData.status"
                     placeholder="请选择">
            <el-option label="全部"
                       value=""></el-option>
            <el-option label="通过"
                       value="1"></el-option>
            <el-option label="不通过"
                       value="2"></el-option>
            <el-option label="未审核"
                       value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary"
                     @click="onSubmit">查询</el-button>
          <el-button type="success"
                     @click="startExamine">开始审核</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="model-table">
      <el-table :data="tableData"
                border
                class="table"
                ref="multipleTable">
        <el-table-column width="55"
                         label="序号"
                         type="index"
                         align="center"></el-table-column>
        <el-table-column width="55"
                         label="ID"
                         prop="id"
                         align="center"></el-table-column>
        <el-table-column prop="title"
                         label="标题"
                         align="center"></el-table-column>
        <el-table-column prop="createTime"
                         label="创建时间"
                         align="center">
          <template v-slot="scope">
            <span> {{  changeDate(scope.row.createTime )}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status"
                         label="审核状态"
                         align="center">
          <template #default="scope">
            <span v-if="scope.row.status == 0"
                  style="color: red">未审核</span>
            <span v-if="scope.row.status == 1"
                  style="color: #409EFF">通过</span>
            <span v-if="scope.row.status == 2"
                  style="color: #333">不通过</span>
          </template>
        </el-table-column>

        <el-table-column label="操作"
                         align="center">
          <template #default="scope">
            <el-button type="text"
                       icon="el-icon-edit"
                       @click="goAudit(scope.row.id)"
                       v-if="scope.row.status == 0 || scope.row.status == 2 ">修改</el-button>
            <el-button type="text"
                       v-else
                       style="color: #333"> 已修改</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background
                     layout="total, prev, pager, next"
                     :current-page="pageIndex"
                     :page-size="20"
                     :total="pageTotal"
                     @current-change="handlePageChange"></el-pagination>
    </div>
  </div>
</template>
<script>
import { getModelTextList } from "../../api/index";

export default {
  name: "ModelTextList",
  data () {
    return {
      formData: {
        title: "",
        status: "",
      },
      tableData: [],
      pageIndex: 1,
      pageTotal: 0,


    }
  },
  mounted () {
    this.getData();

  },
  methods: {
    getData () {
      let params = {
        status: this.formData.status,
        title: this.formData.title,
        pageIndex: this.pageIndex,
        pageSize: "20"
      }
      getModelTextList(params).then(res => {
        console.log(res);
        if (res.code === 0) {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;

        } else {
          this.$message.error(res.data.message);
        }
      })

    },
    // 查找
    onSubmit () {
      this.ExamineFlag = false;// 开始审核标志
      this.pageIndex = 1;
      this.getData();
    },
    // 开始审核
    startExamine () {
      let url = window.location.origin + '/audit';
      window.open(url, '_blank');
    },
    handlePageChange (e) {
      this.pageIndex = e;
      this.getData();
    },
    // 修改
    goAudit (id) {
      let url = window.location.origin + '/audit?id=' + id;
      window.open(url, '_blank');
    },
    changeDate (date) {
      var dateee = new Date(date).toJSON();
      var activeDate = new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
      return activeDate
    },
  }
}
</script>
<style scoped >
.model-list {
  width: 100%;
}
.model-list ::v-deep .el-table td,
.el-table th {
  padding: 0;
}
</style>