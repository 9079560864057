<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 运营位置管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <h3>运营位置管理</h3>
      <div class="handle-box">
        <el-button type="primary"
                   class="handle-del mr10"
                   @click="handleEdit(0, 1, 'add')">添加轮播图</el-button>
        <el-button type="info"
                   class="handle-del mr10"
                   @click="addImgPath">添加/修改类型</el-button>
      </div>
      <el-table :data="bannerData"
                :row-class-name="tableRowClassName"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange">
        <!-- <el-table-column type="selection"
                         width="55"
                         align="center"></el-table-column> -->
        <el-table-column prop="sort"
                         label="排序"
                         width="80"
                         align="center"></el-table-column>
        <el-table-column prop="imageType"
                         label="位置ID"
                         width="80"
                         align="center"></el-table-column>
        <el-table-column prop="url"
                         label="图片"
                         align="center">
          <template #default="scope"
                    align="center">
            <el-image class="table-td-thumb"
                      :src="scope.row.url"
                      :preview-src-list="[scope.row.url]"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="title"
                         align="center"
                         label="标题">
          <template #default="scope">
            <span>{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="jumpUrl"
                         align="center"
                         label="链接">
          <template #default="scope">
            <a :href="scope.row.jumpUrl">{{ scope.row.jumpUrl }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="imageType"
                         align="center"
                         label="位置">
          <template #default="scope">

            <el-select v-model="scope.row.imageType"
                       disabled
                       placeholder="请选择位置"
                       class="handle-select mr10">
              <el-option placeholder="请选择位置"
                         v-for="(item, index) in typeList"
                         :key="index"
                         :label="item.name"
                         :value="item.value"></el-option>
            </el-select>

          </template>
        </el-table-column>

        <el-table-column label="操作"
                         align="center">
          <template #default="scope">
            <el-button type="text"
                       icon="el-icon-edit"
                       @click="handleEdit(scope.$index, scope.row, 'edit')">编辑</el-button>
            <el-button type="text"
                       style="color: red"
                       icon="el-icon-close"
                       v-if="scope.row.isEnable"
                       @click="enableRow(scope.$index, scope.row, false)">禁用</el-button>
            <el-button type="text"
                       v-if="!scope.row.isEnable"
                       icon="el-icon-check"
                       @click="enableRow(scope.$index, scope.row, true)">启用</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination"
           v-if="false">
        <el-pagination background
                       layout="total, prev, pager, next"
                       :current-page="query.pageIndex"
                       :page-size="query.pageSize"
                       :total="pageTotal"
                       @current-change="handlePageChange"></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="添加"
               v-model="editVisible"
               width="30%"
               :close-on-click-modal="false">
      <el-form ref="formData"
               label-width="70px">
        <el-form-item label="标题">
          <el-input v-model.trim="query.title"
                    placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="query.sort"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    placeholder="仅支持数字，越小越靠前"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload class="avatar-uploader"
                     :action="uploadUrl"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccess">
            <img v-if="query.url"
                 :src="query.url"
                 class="avatar banner-url" />
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="链接">
          <el-input v-model="query.jumpUrl"></el-input>
        </el-form-item>
        <el-form-item label="位置">
          <el-select v-model="query.imageType"
                     placeholder="请选择位置"
                     class="handle-select mr10">
            <el-option placeholder="请选择位置"
                       v-for="(item, index) in typeTrue"
                       :key="index"
                       :label="item.name"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="saveEdit">保 存</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 添加配置 -->
    <!-- 编辑弹出框 -->
    <el-dialog title="添加配置"
               v-model="imgPathFlag"
               width="30%">
      <el-form label-width="80px"
               class="type-dialog">
        <el-form-item label="图片位置:">
          <el-input v-model.trim="query.title"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="链接"
                      v-show="false">
          <el-input v-model="query.jumpUrl"></el-input>
        </el-form-item>
      </el-form>
      <el-alert title="添加类型不可删除，请谨慎添加"
                type="warning"
                :closable="false"> </el-alert>
      <div class="typs-btn">
        <el-button @click="imgPathFlag = false"
                   size="mini">取消</el-button>
        <el-button type="primary"
                   @click="savePath"
                   size="mini">添加</el-button>
      </div>

      <el-table :data="typeList"
                border
                class="table"
                header-cell-class-name="table-header">
        <el-table-column prop="id"
                         label="名称"
                         align="center">
          <template #default="scope">
            <span v-if=" scope.row && scope.row .writeFlag">
              <el-input size="mini"
                        placeholder="为空无效"
                        v-model="scope.row.name">
              </el-input>
            </span>
            <span v-else> {{ scope.row.name }}</span>
          </template>

        </el-table-column>

        <el-table-column label="操作"
                         width="360"
                         align="center">
          <template #default="scope">
            <el-button type="text"
                       icon="el-icon-edit"
                       @click="typeEdit(scope.row)">修改</el-button>
            <el-button type="text"
                       icon="el-icon-folder-opened"
                       @click="savePath( scope.row)">保存</el-button>

            <el-button type="text"
                       v-if="scope.row.isEnable"
                       icon="el-icon-circle-close"
                       class="red"
                       @click="changTypeStatus(scope.row.value)">禁用</el-button>
            <el-button type="text"
                       v-if="!scope.row.isEnable"
                       icon="el-icon-circle-check"
                       @click="changTypeStatus(scope.row.value)">启用</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { getBannerList, updateBanner, getChangeTypes, getTypeList, getTypeStatus } from "../../api/index";
import { saBs } from "../../api/base.js";
export default {
  name: "basetable",
  data () {
    return {
      uploadUrl: saBs.$api.upload,
      query: {
        url: "",
        sort: "",
        id: "",
        imageType: "",
        isEnable: true,
        jumpUrl: "",
        title: ''
      },
      bannerData: [],
      tableData: [],
      type: "", //编辑；添加
      handelTag: true, //
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      positionOption: [
        {
          name: "首页轮播图",
          value: 3
        },
        {
          name: "专题图",
          value: 4
        }
      ],
      imgPathFlag: false, // 添加配置弹窗标志
      tags: [
        { name: '标签一', type: '' },
        { name: '标签二', type: 'success' },
        { name: '标签三', type: 'info' },
        { name: '标签四', type: 'warning' },
        { name: '标签五', type: 'danger' },
      ],
      typeList: [],
      typeTrue: [],
      typeFalse: [],
    };
  },
  created () {
    this.getData();
    this.getTypeItem();
  },
  methods: {
    tableRowClassName ({ row }) {

      if (row.imageType === 3) {
        return 'warning-row';
      } else if (row.imageType === 4) {
        return 'success-row';
      }
      return '';

    },
    testUrl (e) {
      console.log(e);
    },
    handleAvatarSuccess (e) {
      if (e.code == 0) {
        this.query.url = e.data.url;
      } else {
        this.$notify.error({
          title: "提示",
          message: "上传失败"
        });
      }
      console.log(e);
    },
    // 启用
    async enableRow (index, row, tag) {
      console.log(index);
      console.log(row);
      console.log(tag);
      let msg = tag == true ? "启用" : "禁用";
      if (!this.handelTag) return;
      try {
        this.handelTag = false;
        let query = row;

        query.isEnable = tag;
        let result = await updateBanner(query);
        console.log(result);
        this.handelTag = true;
        if (result.code == 0) {
          row.isEnable = tag;
          console.log(1);
          this.$notify({
            title: "提示",
            message: `${msg}成功`,
            type: "success"
          });
        } else {
          this.$notify.error({
            title: "提示",
            message: result.data.message
          });
        }
      } catch (error) {
        this.handelTag = true;
        this.$notify.error({
          title: "提示",
          message: `${msg}失败`
        });
      }
    },
    //删除
    delRow () {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },

    // 获取 easy-mock 的模拟数据
    async getData () {
      try {
        let bannerData = await getBannerList();
        if (bannerData.code == 0) {
          console.log(bannerData);
          this.bannerData = bannerData.data;

        } else {
          this.$message.error("获取数据失败");
        }
      } catch (error) {
        this.$message.error("获取数据失败");
      }
    },
    // 触发搜索按钮
    handleSearch () {
      this.$set(this.query, "pageIndex", 1);
      this.getData();
    },
    // 删除操作
    handleDelete (index) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          this.$message.success("删除成功");
          this.tableData.splice(index, 1);
        })
        .catch(() => { });
    },
    // 多选操作
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    delAllSelection () {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 获取所有枚举类型
    getTypeItem () {
      let params = {
        configId: 2,
        name: "",
        value: ""
      }
      getTypeList(params).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.typeList = res.data.result;
          this.typeTrue = this.typeList.filter(item => {
            return item.isEnable === true;
          });
          this.typeFalse = this.typeList.filter(item => {
            return item.isEnable === false;
          });
        } else {
          this.$notify.error({
            title: "提示",
            message: res.message
          });
        }
      })
    },
    // 添加编辑操作
    handleEdit (index, row, type) {

      this.type = type;
      this.idx = index;
      this.form = row;
      this.editVisible = true;
      if (type == "edit") {
        //编辑
        console.log(row);
        this.query = JSON.parse(JSON.stringify(row));
      }
      if (type == "add") {
        this.query = {
          url: "",
          sort: "",
          id: "",
          imageType: "",
          isEnable: true,
          jumpUrl: ""
        };
      }
    },

    // 保存编辑
    async saveEdit () {
      let msg = this.type == "add" ? "添加" : "编辑";
      let pptImgList = this.bannerData.filter(item => {
        return item.imageType == 11
      })

      if (this.query.imageType == 11 && pptImgList.length > 15) {
        this.$notify.error({
          title: "提示",
          message: "秒懂广告位，数量不能大于15个"
        });
        return;
      }
      if (!this.query.url) {
        this.$notify.error({
          title: "提示",
          message: "请上传图片"
        });
        return;
      }
      if (!this.query.jumpUrl) {
        this.$notify.error({
          title: "提示",
          message: "请填写链接"
        });
        return;
      }
      if (!this.query.imageType) {
        this.$notify.error({
          title: "提示",
          message: "请选择位置"
        });
        return;
      }
      if (!this.handelTag) return;
      try {
        this.handelTag = false;
        console.log("this.query", this.query);
        let result = await updateBanner(this.query);
        console.log(result);
        if (result.code == 0) {
          console.log(1);
          this.$notify({
            title: "提示",
            message: `${msg}成功`,
            type: "success"
          });
          setTimeout(() => {
            this.handelTag = true;
            this.editVisible = false;
            if (this.type == "add") {
              //添加类型重新请求数据
              this.getData();
            } else {
              //编辑
              this.bannerData[this.idx] = this.query;
              console.log("this.bannerData", this.bannerData);
            }
          }, 1000);
        } else {
          this.handelTag = true;
          this.$notify.error({
            title: "提示",
            message: result.message
          });
        }
      } catch (error) {
        console.log('err', error);
        this.handelTag = true;
        this.$notify.error({
          title: "提示",
          message: `${msg}失败`
        });
      }
    },
    // 分页导航
    handlePageChange (val) {
      this.$set(this.query, "pageIndex", val);
      this.getData();
    },
    addImgPath () {
      this.query.title = "";
      this.imgPathFlag = true;
      this.getTypeItem();
    },
    typeEdit (row) {
      row.writeFlag = true;

    },
    savePath (row) {
      console.log(row);
      let names = row.name || this.query.title;
      let params = {
        configId: 2,
        name: names,
        value: row.value || "",
        isEnable: true,
      };
      let checkList = this.typeList.filter(item => {
        if (this.query.title != "") {
          return item.name === this.query.title
        }
      });
      if (names.length <= 0) {
        this.$notify.error({
          title: "提示",
          message: "请输入图片位置"
        });
        return false;
      }
      console.log(checkList, 99);
      if (checkList.length > 0) {
        this.$notify.error({
          title: "提示",
          message: "名称不能重复。"
        });
        return false;
      }
      getChangeTypes(params).then(res => {
        this.getTypeItem();
        if (res.code == 0) {
          this.$notify.success({
            title: "提示",
            message: res.message
          });
        } else {
          this.$notify.error({
            title: "提示",
            message: res.message
          });
        }
      })
    },
    // 改变状态
    changTypeStatus (value) {
      let params = {
        configId: 2,
        value,
      };
      getTypeStatus(params).then(res => {
        this.getTypeItem();
        if (res.code == 0) {
          this.$notify.success({
            title: "提示",
            message: res.message
          });
        } else {
          this.$notify.error({
            title: "提示",
            message: res.message
          });
        }
      })
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
  margin-top: 10px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.banner-url {
  width: 100%;
  height: 100%;
}
.container /deep/ .warning-row {
  background-color: #f0f9eb;
}
.tag-p {
  margin-right: 10px;
  margin-top: 6px;
  cursor: pointer;
}
.typs-btn {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
}
.type-dialog /deep/ .el-form-item {
  margin-bottom: 6px;
}
</style>
