<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades">报表统计</i>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <span>类型:</span>
        <el-select v-model="tabFlag"
                   @change="changeLabel"
                   placeholder="请选择">
          <el-option v-for="item in options"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
        <div class="input-box"
             v-if="tabFlag == 1 ">
          <el-input class="inputq"
                    v-model="query.superChannel"
                    placeholder="一级渠道"></el-input>
          <el-input class="inputq"
                    v-model="query.parentChannel"
                    placeholder="二级渠道"></el-input>
          <el-input class="inputq"
                    v-model="query.ccode"
                    placeholder="渠道名称"></el-input>
        </div>
        <div class="input-box"
             v-if="tabFlag == 1 || tabFlag == 2 ">
          <span>日期:</span>
          <el-date-picker v-model="value2"
                          style="width: 240px"
                          :clearable="false"
                          :default-value="defaultDate"
                          @change="changeTimer"
                          format="YYYY-MM-DD"
                          value-format="YYYY-MM-DD"
                          type="daterange"
                          align="right"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :shortcuts="shortcuts">
          </el-date-picker>
        </div>

        <el-button type="primary ml10"
                   icon="el-icon-search"
                   @click="changeTabs"
                   v-if="tabFlag != 3 ">搜索</el-button>

      </div>
      <el-table :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header">

        <el-table-column prop="date"
                         label="日期"
                         v-if="tabFlag == 1 || tabFlag == 2 "
                         width="120"
                         align="center"></el-table-column>
        <el-table-column prop="superChannel"
                         v-if="tabFlag == 1"
                         label="一级渠道"
                         align="center">
          <template v-slot="scope">
            <span> {{  scope.row.superChannel ? scope.row.superChannel : "无"  }}</span>
          </template>

        </el-table-column>
        <el-table-column prop="parentChannel"
                         v-if="tabFlag == 1"
                         label="二级渠道"
                         align="center">
          <template v-slot="scope">
            <span> {{  scope.row.parentChannel ? scope.row.parentChannel : "无"  }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="ccode"
                         v-if="tabFlag == 1"
                         label="渠道名称"
                         align="center"></el-table-column>
        <el-table-column prop="pv"
                         label="访问量（PV）"
                         align="center">
          <template v-slot="scope">
            <span> {{  scope.row.pv  }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="uv"
                         label="访问用户量（UV）"
                         align="center"></el-table-column>
        <el-table-column prop="newUv"
                         label="新增访问用户量（UV）"
                         align="center"></el-table-column>
        <el-table-column prop="loginUserCount"
                         label="登录用户"
                         align="center"></el-table-column>
        <el-table-column prop="newUserCount"
                         label="新注册用户"
                         align="center"></el-table-column>
        <el-table-column prop="clickDownload"
                         label="点击立即下载次数"
                         align="center"></el-table-column>
        <el-table-column prop="clickDownloadFirst"
                         label="点击立即下载用户"
                         align="center"></el-table-column>
        <el-table-column prop="orderCount"
                         label="订单数"
                         align="center"></el-table-column>
        <el-table-column prop="userOrderCount"
                         label="用户订单"
                         align="center"></el-table-column>
        <el-table-column prop="payOrderCount"
                         label="支付订单"
                         align="center"></el-table-column>
        <el-table-column prop="payAmount"
                         label="支付金额"
                         align="center"></el-table-column>
        <el-table-column prop="downloadCount"
                         label="模板下载次数"
                         align="center"></el-table-column>
        <el-table-column prop="newUserRate"
                         label="新用户占比"
                         align="center">
        </el-table-column>
        <el-table-column prop="loginRate"
                         label="登录转化率"
                         align="center">
        </el-table-column>

      </el-table>
      <!-- 结束 -->
      <div class="pagination">
        <el-pagination background
                       layout="total, prev, pager, next"
                       :current-page="query.pageIndex"
                       :page-size="query.pageSize"
                       :total="total"
                       @current-change="handlePageChange"></el-pagination>
      </div>
    </div>
  </div>
</template>


<script>
import {
  getChannelDay,
  getTotalDay,
  getActualTime,
} from "../../api/index";
export default {
  name: "reportForm",
  data () {
    return {
      defaultDate: [],
      query: {
        pageIndex: 1,
        pageSize: 20,
        superChannel: '',
        parentChannel: '',
        ccode: '',
      },
      tableData: [],
      total: 0,
      shortcuts: [{
        text: '最近一周',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        })(),
      }, {
        text: '最近一个月',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        })(),
      }, {
        text: '最近三个月',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        })(),
      }],
      value2: '',
      options: [{
        value: '1',
        label: '渠道数据统计按天'
      }, {
        value: '2',
        label: '总表数据统计按天'
      }, {
        value: '3',
        label: '当天实时查看'
      }],
      tabFlag: '1',
      startDate: '',
      endDate: '',

    };
  },
  mounted () {
    this.channelDayData();
  },
  methods: {
    changeTimer (v) {
      //  格式化时间
      const d1 = new Date(v[0]);
      const d2 = new Date(v[1]);
      const resDate = d1.getFullYear() + '-' + this.p((d1.getMonth() + 1)) + '-' + this.p(d1.getDate());
      const eDate = d2.getFullYear() + '-' + this.p((d2.getMonth() + 1)) + '-' + this.p(d2.getDate());
      this.startDate = resDate;
      this.endDate = eDate;
      console.log(resDate, eDate);
    },
    p (s) {
      return s < 10 ? '0' + s : s
    },

    // 格式化日期
    formatTimer (date) {
      var dateee = new Date(date).toJSON();
      var activeDate = new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
      return activeDate
    },
    changeLabel () {
      this.value2 = [];
      this.startDate = '';
      this.endDate = '';
      this.changeTabs();
    },

    changeTabs () {

      this.query.pageIndex = 1;
      console.log(this.tabFlag);
      if (this.tabFlag === '1') {
        this.channelDayData();
      } else if (this.tabFlag === '2') {
        this.totalDayData();
      } else if (this.tabFlag === '3') {
        this.actualTimeData();
      }

    },
    // 渠道统计
    channelDayData () {
      let params = {
        pageIndex: this.query.pageIndex,
        pageSize: 20,
        superChannel: this.query.superChannel,
        parentChannel: this.query.parentChannel,
        ccode: this.query.ccode,
        startDate: this.startDate,
        endDate: this.endDate,
      }
      getChannelDay(params).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        } else {
          this.$message(res.message)
        }
      })
    },
    // 总表统计
    totalDayData () {
      let params = {
        pageIndex: this.query.pageIndex,
        pageSize: 20,
        startDate: this.startDate,
        endDate: this.endDate,
      }
      getTotalDay(params).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        } else {
          this.$message(res.message)
        }
      })
    },
    // 当日统计
    actualTimeData () {
      let params = {
        pageIndex: this.query.pageIndex,
        pageSize: 20,
      }
      getActualTime(params).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = [];
          this.tableData[0] = res.data;
          console.log();
          this.total = 1;
        } else {
          this.$message(res.message)
        }
      })
    },
    // 分页导航
    handlePageChange (val) {
      this.query.pageIndex = val;
      if (this.tabFlag === '1') {
        this.channelDayData();
      } else if (this.tabFlag === '2') {
        this.totalDayData();
      } else if (this.tabFlag === '3') {
        this.actualTimeData();
      }
    }
  }
}
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.handle-box .inputq {
  width: 180px;
  margin-left: 12px;
}

.handle-box .input-box {
  display: inline-block;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.w80 {
  width: 80px;
}
.ml10 {
  margin-left: 10px;
}
.btns {
  margin-bottom: 10px;
}
</style>

