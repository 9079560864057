export const jsTool = {
	data() {
		return {

		}
	},
	methods: {
		// 格式化时间
		formatTimer(date) {
			var dateee = new Date(date).toJSON();
			var activeDate = new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
			return activeDate
		},
		showlog() {
			console.log(123);
		}

	},


}