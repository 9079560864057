<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 支付套餐配置
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <h3>支付套餐配置</h3>
      <div class="handle-box">
        <el-alert title="三种商品类型，必须为启用状态；次数为0时，表示不限制下载次数。"
                  :closable="false"
                  type="warning"> </el-alert>
      </div>
      <el-table :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange">
        <el-table-column prop="id"
                         label="排序"
                         align="center">
          <template #default="scope">
            <span v-if=" scope.row && scope.row .writeFlag">
              <el-input size="mini"
                        placeholder="为空无效"
                        v-model="scope.row.sort">
              </el-input>
            </span>
            <span v-else> {{ scope.row.sort }}</span>
          </template>

        </el-table-column>
        <el-table-column prop="id"
                         width="120"
                         label="套餐名称"
                         align="center">

          <template #default="scope">
            <span v-if="scope.row && scope.row .writeFlag">
              <el-input size="mini"
                        placeholder="中英文字符"
                        maxlength="10"
                        v-model="scope.row.name">
              </el-input>
            </span>
            <span v-else> {{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         label="实付价格"
                         align="center">
          <template #default="scope">
            <span v-if="scope.row && scope.row .writeFlag">
              <el-input size="mini"
                        @keyup="checkNumTwo"
                        placeholder="0.01~9999"
                        v-model="scope.row.price">
              </el-input>
            </span>
            <span v-else> {{ scope.row.price }}</span>
          </template>

        </el-table-column>
        <el-table-column prop="id"
                         label="原价金额"
                         width="120"
                         align="center">
          <template #default="scope">
            <span v-if="scope.row && scope.row .writeFlag">
              <el-input size="mini"
                        @keyup="checkNumTwo"
                        placeholder="0.01~9999"
                        v-model="scope.row.rawPrice">
              </el-input>
            </span>
            <span v-else> {{ scope.row.rawPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         label="总次数"
                         align="center">
          <template #default="scope">
            <span v-if="scope.row && scope.row .writeFlag">
              <el-input size="mini"
                        type="number"
                        min="0"
                        max="9999"
                        placeholder="为0则为永久"
                        v-model.number="scope.row.downloadTime">
              </el-input>
            </span>
            <span v-else> {{ scope.row.downloadTime }}</span>
          </template>

        </el-table-column>

        <el-table-column prop="id"
                         label="PPT天次数"
                         align="center">
          <template #default="scope">
            <span v-if="scope.row && scope.row .writeFlag">
              <el-input size="mini"
                        type="number"
                        min="0"
                        max="9999"
                        placeholder="为0则为永久"
                        v-model.number="scope.row.pptDailyTime">
              </el-input>
            </span>
            <span v-else> {{ scope.row.pptDailyTime }}</span>
          </template>

        </el-table-column>
        <el-table-column prop="id"
                         label="Word天次数"
                         align="center">
          <template #default="scope">
            <span v-if="scope.row && scope.row .writeFlag">
              <el-input size="mini"
                        type="number"
                        min="0"
                        max="9999"
                        placeholder="为0则为永久"
                        v-model.number="scope.row.wordDailyTime">
              </el-input>
            </span>
            <span v-else> {{ scope.row.wordDailyTime }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="id"
                         label="Excel天次数"
                         align="center">
          <template #default="scope">
            <span v-if="scope.row && scope.row .writeFlag">
              <el-input size="mini"
                        type="number"
                        placeholder="为0则为永久"
                        v-model="scope.row.excelDailyTime">
              </el-input>
            </span>
            <span v-else> {{ scope.row.excelDailyTime }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="id"
                         label="当前状态"
                         align="center">
          <template #default="scope">
            <span> {{ scope.row.enable?"启用中": "禁用中" }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作"
                         width="360"
                         align="center">
          <template #default="scope">
            <el-button type="text"
                       icon="el-icon-edit"
                       @click="handleEdit(scope.$index, scope.row)">修改</el-button>
            <el-button type="text"
                       icon="el-icon-folder-opened"
                       @click="handleSave(scope.$index, scope.row)">保存</el-button>

            <el-button type="text"
                       icon="el-icon-circle-close"
                       v-if="scope.row.enable"
                       class="red"
                       @click="changeStatus(scope.row)">禁用</el-button>
            <el-button type="text"
                       icon="el-icon-circle-check"
                       v-else
                       @click="changeStatus( scope.row)">启用</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="add-title"
           v-if="false"
           @click="addGoods">
        +添加商品
      </div>
      <div class="add-title save-good"
           @click="savePayData">
        保存商品
      </div>
      <!-- <div class="pagination">
        <el-pagination background
                       layout="total, prev, pager, next"
                       :current-page="query.pageIndex"
                       :page-size="query.pageSize"
                       :total="pageTotal"
                       @current-change="handlePageChange"></el-pagination>
      </div> -->
    </div>

  </div>
</template>

<script>
import { fetchData, getPayList, savePayList } from "../../api/index";
var md5 = require("@/assets/js/md5.min.js");

export default {
  name: "basetable",
  data () {
    return {
      query: {
        address: "",
        name: "",
        pageIndex: 1,
        pageSize: 10
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      writeFlag: false,// 行编辑状态
    };
  },
  created () {
    this.getData();
    this.getPayData();
  },
  methods: {
    changeStatus (row) {
      let str = row.enable ? "禁用" : "启用";
      this.$confirm("确定要" + str + "吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          row.enable = !row.enable;
          this.savePayData();
        })
        .catch(() => { });
    },
    // 获取列表
    getPayData () {
      let params = {
        ccode: "测试渠道",
        pcode: "PPT_QY",
        uuid: "uuxx12021xee",
        sign: "0BB930E4672BBDE054D0E238FCF63CDE"
      };
      getPayList(params).then(res => {
        console.log(res);
        if (res.code == 0) {
          let list = [];
          list.push(res.data.gold);
          list.push(res.data.lifeLong);
          list.push(res.data.normal);
          this.tableData = list;

        }
      })
    },
    // 保存列表
    savePayData () {
      this.tableData.map(item => {
        let n = String(item.price).indexOf(".");
        if (n > -1) {
          item.price = String(item.price).substr(0, n) + String(item.price).substr(n, 3);

        }
        let m = String(item.rawPrice).indexOf(".");
        if (m > -1) {
          item.rawPrice = String(item.rawPrice).substr(0, m) + String(item.rawPrice).substr(m, 3);
        }
      })

      let normal = this.tableData[2];
      let gold = this.tableData[0];
      let lifeLong = this.tableData[1];
      let priceList = this.tableData.filter(item => {
        return Number(item.price) < 0.01 || Number(item.price) > 9999 || item.price == ""
      });
      if (priceList.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "实付价格范围在0.01~9999之间,不能为空"
        });
        return false;
      }

      let rawPriceList = this.tableData.filter(item => {
        return Number(item.rawPrice) < 0.01 || Number(item.rawPrice) > 9999 || item.rawPrice == ""
      })
      console.log(rawPriceList);
      if (rawPriceList.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "原始价格范围在0.01~9999之间"
        });
        return false;

      }

      let downCount = this.tableData.filter(item => {
        console.log('====================================');
        console.log(Number(item.downloadTime) < 0, item.downloadTime === "");
        console.log('====================================');
        return Number(item.downloadTime) < 0 || item.downloadTime === ""
      })
      if (downCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "下载总次数大于等于0,不能为空"
        });
        return false;

      }

      let pptDownCount = this.tableData.filter(item => {
        return Number(item.pptDailyTime) < 0 || item.pptDailyTime === ""
      })
      if (pptDownCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "PPT次数大于等于0，不能为空"
        });
        return false;

      }

      let wordDownCount = this.tableData.filter(item => {
        return Number(item.wordDailyTime) < 0 || item.wordDailyTime === ""
      })
      if (wordDownCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "Word次数大于等于0,不能为空"
        });
        return false;

      }

      let excelDownCount = this.tableData.filter(item => {
        return Number(item.excelDailyTime) < 0 || item.excelDailyTime === ""
      })
      if (excelDownCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "Excel次数大于等于0,不能为空"
        });
        return false;

      }
      let sortList = this.tableData.filter(item => {
        return item.sort === "" || Number(item.sort) < 0 || item.name.length <= 0
      })

      if (sortList.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "排序和套餐名称为必填项"
        });
        return false;
      } else {
        this.tableData.map(item => {
          item.writeFlag = false;
        })
      }

      let goldStatus = String(gold.enable).replace(String(gold.enable)[0], String(gold.enable)[0].toUpperCase());
      let lifeLongStatus = String(lifeLong.enable).replace(String(lifeLong.enable)[0], String(lifeLong.enable)[0].toUpperCase());
      let normalStatus = String(normal.enable).replace(String(normal.enable)[0], String(normal.enable)[0].toUpperCase());


      let str = "1bca15648633405ab86330d0d97aceb6" + gold.downloadTime + goldStatus +
        gold.excelDailyTime + gold.memberType + gold.name + gold.packageName + gold.pptDailyTime + gold.price + gold.rawPrice + gold.sort +
        gold.wordDailyTime + lifeLong.downloadTime + lifeLongStatus + lifeLong.excelDailyTime + lifeLong.memberType + lifeLong.name +
        lifeLong.packageName + lifeLong.pptDailyTime + lifeLong.price + lifeLong.rawPrice + lifeLong.sort + lifeLong.wordDailyTime +
        normal.downloadTime + normalStatus + normal.excelDailyTime + normal.memberType + normal.name + normal.packageName + normal.pptDailyTime +
        normal.price + normal.rawPrice + normal.sort + normal.wordDailyTime;

      let sign = md5(str).toUpperCase();


      let params = {
        normal,
        gold,
        lifeLong,
        sign
      }
      savePayList(params).then(res => {
        console.log('====================================');
        console.log(res);
        console.log('====================================');
        if (res.code === 0) {
          this.$notify.success({
            title: "提示",
            message: "保存成功"
          });
        } else {
          this.$notify.error({
            title: "提示",
            message: res.message
          });
        }
      })
    },
    checkNumTwo (e) {
      // 通过正则过滤小数点后两位
      e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null;

    },

    //禁用
    DisableRow () {
      this.$confirm('将禁用该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '禁用成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消禁用'
        });
      });
    },
    // 启用
    enableRow () {
      this.$confirm('将启用该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '启用成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消启用'
        });
      });
    },
    //删除
    delRow () {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },


    handleRemove (file) {
      console.log(file);
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload (file) {
      console.log(file);
    },
    // 获取 easy-mock 的模拟数据
    getData () {
      fetchData(this.query).then(res => {
        console.log(res);
        // this.tableData = res.list;
        this.pageTotal = res.pageTotal || 50;
      });
    },
    // 触发搜索按钮
    handleSearch () {
      this.$set(this.query, "pageIndex", 1);
      this.getData();
    },
    // 删除操作
    handleDelete (index) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          this.$message.success("删除成功");
          this.tableData.splice(index, 1);
        })
        .catch(() => { });
    },
    // 多选操作
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    delAllSelection () {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit (index, row) {
      console.log(index);
      console.log(row);
      row.writeFlag = true;
    },
    addGoods () {
      let obj = { id: 11 };
      this.tableData.push(obj);
      this.pageTotal = this.tableData.length;
      console.log(this.pageTotal);
    },
    handleSave (index, row) {
      console.log(row);
      // row.writeFlag = false;
      this.savePayData();
    },
    // 保存编辑
    saveEdit () {

      this.$message.success(`修改第 ${this.idx + 1} 行成功`);
      this.$set(this.tableData, this.idx, this.form);
    },
    // 分页导航
    handlePageChange (val) {
      this.$set(this.query, "pageIndex", val);
      this.getData();
    }
  }
}
</script>

<style scoped lang='scss'>
.handle-box {
  margin-top: 8px;
  margin-bottom: 8px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.container {
  position: relative;
  top: 0;
  left: 0;
}
.add-title {
  margin-top: 8px;
  width: 100%;
  line-height: 40px;
  border-radius: 4px;
  // border: 1px solid #409eff;
  background: #409eff;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.save-good {
  border: 1px solid #67c23a;
  background: #67c23a;
  color: #fff;
}
</style>
