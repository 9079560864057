<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 搜索词配置
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="types">
        <span>文档类型: </span>
        <el-radio v-model="types"
                  :label="parseInt(1)">PPT</el-radio>
        <el-radio v-model="types"
                  :label="parseInt(2)">Word</el-radio>

        <el-radio v-model="types"
                  :label="parseInt(3)">Excel</el-radio>
        <el-radio v-model="types"
                  :label="parseInt(4)">素材</el-radio>
      </div>

      <div class="handle-box">
        搜索框搜索词
        <el-input v-model="query.searchWordsIndexList"
                  placeholder=""
                  size="medium"
                  @input="inputFn(query.searchWordsIndexList, 'searchWordsIndexList',5)"
                  class="handle-input mr10"></el-input>
      </div>
      <div class="tips">多个搜索词，用英文逗号隔开，最多五个词。</div>
      <div class="handle-box2">
        热门搜索词
        <el-input v-model="query.hotWordsIndexList"
                  placeholder=""
                  @input="inputFn(query.hotWordsIndexList, 'hotWordsIndexList',5)"
                  class="handle-input mr10"></el-input>
      </div>
      <div class="tips">多个搜索词，用英文逗号隔开，最多五个词。</div>
      <div class="handle-box2">
        搜索词排行
        <el-input v-model="query.searchWordsIndexSort"
                  placeholder=""
                  @input="inputFn(query.searchWordsIndexSort, 'searchWordsIndexSort',10)"
                  class="handle-input mr10"></el-input>
        <template v-if="updateTime">{{updateTime}}保存成功</template>

      </div>
      <div class="tips">多个搜索词，用英文逗号隔开，最多十个词。</div>
      <div class="save">
        <el-button type="primary"
                   @click="saveData">{{ saveName()}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getSearchWords, setHotWordList } from "../../api/index";
export default {
  name: "basetable",
  data () {
    return {
      query: {
        searchWordsIndexList: "",
        hotWordsIndexList: "",
        searchWordsIndexSort: ""
      },
      updateTime: "",
      types: 1,
      wordList: [],// 热搜索词
      name: "保存PPT配置"
    };
  },
  mounted () {
    this.getData();
    // this.getWordList();
  },
  watch: {
    types: {
      handler (newVal) {
        console.log(newVal);
        this.changeWordList(this.wordList, newVal);
      }
    },

  },
  methods: {
    saveName () {

      if (this.types == 1) {
        this.name = '保存PPT配置';
      } else if (this.types == 2) {
        this.name = '保存Word配置';
      } else if (this.types == 3) {
        this.name = '保存Excel配置';
      } else {
        this.name = '保存素材配置';
      }
      return this.name;

    },
    // 设置热搜词
    async setWordList () {


      let params = {
        docType: this.types,
        searchWordConfigs: [
          {
            configName: "searchWordsIndexList",
            configValue: this.query.searchWordsIndexList
          },
          {
            configName: "hotWordsIndexList",
            configValue: this.query.hotWordsIndexList
          },
          {
            configName: "searchWordsIndexSort",
            configValue: this.query.searchWordsIndexSort
          }
        ]
      }
      try {
        let res = await setHotWordList(
          params
        );
        if (res.code == 0) {
          console.log(res);
          this.$message.success("保存成功");
          this.updateTime = this.renderTime(res.data);

        } else {
          this.$message.error("保存失败");

        }
      } catch (error) {
        console.log(error);
      }
    },

    renderTime (date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },
    inputFn (value, type, len) {
      let data = value.replace("，", ",");
      let dateArr = data.split(",");
      let dateArrLimit = dateArr.slice(0, len);
      this.query[type] = dateArrLimit.join(",");
    },
    //禁用
    saveData () {

      this.$confirm("将保存该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        this.setWordList();
      })
        .catch(() => {
          console.log(111);
        });
    },
    // 获取热搜词
    async getData (id) {
      let flag = id || 1;
      try {
        let searchWords = await getSearchWords();
        if (searchWords.code == 0) {
          const data = searchWords.data;// 数组
          this.wordList = data;
          this.changeWordList(this.wordList, flag)

        }
      } catch (error) {
        this.$message.error("获取数据失败");
      }
    },
    changeWordList (data, flag) {
      let list = data.filter(item => {
        return item.configType == flag
      })
      if (list.length > 0) {
        this.query = list[0];
      }

    },
    // 触发搜索按钮
    handleSearch () {
      this.$set(this.query, "pageIndex", 1);
      this.getData(1);
    },
    // 删除操作
    handleDelete (index) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          this.$message.success("删除成功");
          this.tableData.splice(index, 1);
        })
        .catch(() => { });
    },
    // 多选操作
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    delAllSelection () {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit (index, row) {
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    // 保存编辑
    saveEdit () {
      this.editVisible = false;
      this.$message.success(`修改第 ${this.idx + 1} 行成功`);
      this.$set(this.tableData, this.idx, this.form);
    },
    // 分页导航
    handlePageChange (val) {
      this.$set(this.query, "pageIndex", val);
      this.getData();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 6px;
}
.handle-box2 {
  margin-top: 20px;
  margin-bottom: 6px;
}
.tips {
  font-size: 12px;
  color: #999;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.types {
  padding: 10px;
  padding-bottom: 20px;
}
.save {
  padding-top: 16px;
}
</style>
