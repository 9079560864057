<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 专题页配置
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-button type="primary"
                   class="handle-del mr10"
                   @click="handleEdit(0, 1, 'add')">添加专题页</el-button>

      </div>
      <el-table :data="bannerData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange">
        <el-table-column prop="id"
                         label="ID"
                         align="center"></el-table-column>
        <el-table-column prop="name"
                         label="专题页名称"
                         align="center"></el-table-column>
        <el-table-column prop="name"
                         label="专题类型"
                         align="center">
          <template #default="scope"
                    align="center">
            <span v-if="scope.row.imageType == 5">专题</span>
            <span style="color: red"
                  v-if="scope.row.imageType == 6">精选</span>
            <span style="color: #409EFF"
                  v-if="scope.row.imageType == 7">首页楼层</span>
            <span style="color: #67C23A"
                  v-if="scope.row.imageType == 8">专题页楼层</span>

          </template>
        </el-table-column>

        <el-table-column prop="keywords"
                         label="SEO关键字"
                         align="center"></el-table-column>
        <el-table-column prop="url"
                         label="专题图"
                         align="center">
          <template #default="scope"
                    align="center">
            <el-image class="table-td-thumb"
                      :src="scope.row.imageUrl"
                      :preview-src-list="[scope.row.imageUrl]"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="更新时间"
                         align="center">
          <template v-slot="scope">
            <span> {{  formatTimer(scope.row.updateTime )}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="ids"
                         label="专题页PPT"
                         align="center">
          <template #default="scope">
            <el-popover effect="light"
                        trigger="click"
                        placement="top">
              <template #default>
                <p> {{ scope.row.ids }}</p>
              </template>
              <template #reference>
                <div class="name-wrapper">
                  <el-tag size="medium">{{ scope.row.ids.slice(0,20) }}</el-tag>
                </div>
              </template>
            </el-popover>
          </template>

        </el-table-column>

        <el-table-column prop="url"
                         align="center"
                         label="链接地址">
          <template #default="scope">
            <a :href="scope.row.url"
               v-if=" scope.row.imageType == 5">
              {{ '/collect/'+scope.row.id }}
            </a>
            <a :href="/collectPage/+ scope.row.id"
               v-if="scope.row.imageType == 8 || scope.row.imageType == 7">{{ '/collectPage/'+ scope.row.id}}</a>
            <span> {{  scope.row.jxUrl }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         align="center">
          <template #default="scope">
            <el-button type="text"
                       icon="el-icon-edit"
                       @click="handleEdit(scope.$index, scope.row, 'edit')">修改</el-button>
            <el-button type="text"
                       icon="el-icon-error"
                       class="red"
                       @click="delRow( scope.row )">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination background
                       layout="total, prev, pager, next"
                       :current-page="pageIndex"
                       page-size="20"
                       :total="pageTotal"
                       @current-change="handlePageChange"></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog :title="showTitle"
               v-model="editVisible"
               width="60%"
               :close-on-click-modal="false">
      <el-form ref="formData"
               label-width="100px">
        <el-form-item label="专题页名称:"
                      required>
          <el-input v-model.trim="query.name"
                    placeholder="请输入"
                    maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="SEO关键字:">
          <el-input v-model.trim="query.keywords"
                    type="textarea"
                    :rows="3"
                    placeholder="请输入关键字，英文逗号隔开"
                    maxlength="100"></el-input>
        </el-form-item>
        <el-form-item label="描述:">
          <el-input v-model.trim="query.description"
                    placeholder="请输入描述"
                    maxlength="150"></el-input>
        </el-form-item>

        <el-form-item label="专题图:"
                      required>
          <el-upload class="avatar-uploader"
                     :beforeUpload="beforeRemove"
                     :action="uploadUrl"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccess">
            <img v-if="query.imageUrl"
                 :src="query.imageUrl"
                 class="avatar banner-url" />
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="专题页PPT:"
                      required>
          <el-input v-model.trim="query.ids"
                    placeholder="请输入ppt文档的id，英文逗号隔开"
                    type="textarea"
                    :rows="3"></el-input>
        </el-form-item>
        <!-- <el-form-item label="专题页链接:"
                      required>
          <el-input v-model.trim="query.id"
                    :disabled="true"
                    placeholder="请输入链接地址"
                    type="textarea"
                    :rows="3"></el-input>
        </el-form-item> -->

        <el-form-item label="专题类型:">
          <el-radio v-model="query.imageType"
                    :label="parseInt(5)">专题页PPT</el-radio>
          <el-radio v-model="query.imageType"
                    :label="parseInt(6)">精选PPT</el-radio>

          <el-radio v-model="query.imageType"
                    :label="parseInt(7)">首页楼层</el-radio>
          <el-radio v-model="query.imageType"
                    :label="parseInt(8)">专题页楼层</el-radio>

        </el-form-item>
        <el-form-item label="排序:"
                      v-if="query.imageType ==6 || query.imageType ==7 || query.imageType ==8  ">
          <el-input v-model.trim="query.sort"
                    text="number"
                    placeholder="请输入排序"></el-input>
        </el-form-item>
        <el-form-item label="查看更多:"
                      v-if="query.imageType ==6 || query.imageType ==7">
          <el-input v-model.trim="query.url"
                    placeholder="请输入链接地址"></el-input>
        </el-form-item>

      </el-form>
      <div class="tables"
           v-if="query.imageType ==7 ||  query.imageType == 8">
        <el-table :data="query.floorPageList"
                  border
                  class="table"
                  ref="multipleTable"
                  header-cell-class-name="table-header"
                  @selection-change="handleSelectionChange">
          <el-table-column label="二级标题"
                           width="120"
                           align="center">
            <template #default="scope">
              <el-input v-model="scope.row.title"
                        size="mini"
                        placeholder="请输入名称" />
            </template>
          </el-table-column>
          <el-table-column prop="sort"
                           label="ID"
                           align="center">
            <template #default="scope">
              <el-input v-model="scope.row.ids"
                        type="textarea"
                        size="mini"
                        placeholder="请输入内容" />

            </template>
          </el-table-column>
          <el-table-column prop="sort"
                           label="链接"
                           align="center">
            <template #default="scope">
              <el-input v-model.number="scope.row.url"
                        type="textarea"
                        size="mini"
                        placeholder="请输入链接" />

            </template>
          </el-table-column>

          <el-table-column label="操作"
                           width="80"
                           align="center">
            <template #default="scope">
              <el-button size="small"
                         type="text"
                         icon="el-icon-delete"
                         class="blue"
                         @click="delListData(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary"
                   class="handle-del mr10 mt10"
                   @click="addList()">添加数据</el-button>

      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="saveEdit">保 存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { updateBanner, getTopPicList, getTopPicAdd, getTopPicDelete, getFloorData } from "../../api/index";
import { saBs } from "../../api/base.js";
import { jsTool } from "../../utils/mixin"
export default {
  name: "basetable",
  data () {
    return {
      uploadUrl: saBs.$api.upload,
      pageIndex: 1,
      query: {
        url: "",
        sort: "",
        id: "",
        imageType: "",
        jxUrl: "",
        isEnable: true,
        jumpUrl: "",
        pageIndex: 1,
        pageSize: 20,
        description: "",
        floorPageList: [
          { id: "", title: "", ids: "", url: "" },]

      },
      bannerData: [],
      tableData: [],
      type: "", //编辑；添加
      handelTag: true, //
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      positionOption: [
        {
          name: "首页轮播图",
          value: 3
        },
        {
          name: "专题图",
          value: 4
        }
      ],
      showTitle: "修改专题页",
      activeNames: ['1'],
      list: [

      ]

    };
  },
  mixins: [jsTool],
  created () {
    this.getData();
    this.showlog();


  },
  methods: {
    addList () {
      if (this.query.floorPageList == null) {
        this.query.floorPageList = [];
      }
      this.query.floorPageList.push({
        id: "",
        title: "",
        ids: "",
        url: ""
      })
    },
    delListData (i) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.query.floorPageList.splice(i, 1);
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });

    },
    changeLabel () {

    },
    testUrl (e) {
      console.log(e);
    },
    // 上传图片校验
    beforeRemove (file, fileList) {
      console.log(fileList);
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg' || testmsg === 'JPG' || testmsg === 'png' || testmsg === 'PNG';
      const isLt50M = file.size / 1024 / 1024 < 1;
      if (!extension) {
        this.$message({
          message: '上传文件只能是jpg或者png格式!',
          type: 'error'
        });
        return false;//必须加上return false; 才能阻止
      }
      console.log(file)
      if (!isLt50M) {
        this.$message({
          message: '上传文件大小不能超过 1MB!',
          type: 'error'
        });
        return false;
      }
      return extension && isLt50M;
    },
    handleAvatarSuccess (e) {
      if (e.code == 0) {
        this.query.imageUrl = e.data.url;
      } else {
        this.$notify.error({
          title: "提示",
          message: "上传失败"
        });
      }
      console.log(e);
    },
    // 启用
    async enableRow (index, row, tag) {
      console.log(index);
      console.log(row);
      console.log(tag);
      let msg = tag == true ? "启用" : "禁用";
      if (!this.handelTag) return;
      try {
        this.handelTag = false;
        let query = row;

        query.isEnable = tag;
        let result = await updateBanner(query);
        console.log(result);
        this.handelTag = true;
        if (result.code == 0) {
          row.isEnable = tag;
          console.log(1);
          this.$notify({
            title: "提示",
            message: `${msg}成功`,
            type: "success"
          });
        } else {
          this.$notify.error({
            title: "提示",
            message: result.data.message
          });
        }
      } catch (error) {
        this.handelTag = true;
        this.$notify.error({
          title: "提示",
          message: `${msg}失败`
        });
      }
    },
    //删除
    delRow (row) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          getTopPicDelete(row.id).then(res => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
              this.getData();
            }
          });

        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    async getFloor (id) {
      let res = await getFloorData(id);
      this.query = res.data;
    },

    // 获取 easy-mock 的模拟数据
    async getData () {
      try {
        let params = {
          name: "",
          isEnable: "",
          dateBeg: "",
          dateEnd: "",
          pageIndex: this.pageIndex,
          pageSize: 20,
        }
        let bannerData = await getTopPicList(params);

        if (bannerData.code == 0) {
          console.log(bannerData);
          this.bannerData = bannerData.data.data;
          this.pageTotal = bannerData.data.total;
        } else {
          this.$message.error("获取数据失败");
        }
      } catch (error) {
        this.$message.error("获取数据失败");
      }
    },
    // 触发搜索按钮
    handleSearch () {
      this.$set(this.query, "pageIndex", 1);
      this.getData();
    },
    // 删除操作
    handleDelete (index) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          this.$message.success("删除成功");
          this.tableData.splice(index, 1);
        })
        .catch(() => { });
    },
    // 多选操作
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    delAllSelection () {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 添加编辑操作
    handleEdit (index, row, type) {

      console.log(index, row, type);

      this.type = type;
      this.idx = index;
      this.form = row;
      this.editVisible = true;


      if (type == "edit") {
        //编辑
        this.showTitle = "修改专题页";
        this.getFloor(row.id);
        // this.query = JSON.parse(JSON.stringify(row));
        // this.query.imageType = String(this.query.imageType);
      }
      if (type == "add") {
        if (this.pageTotal >= 60) {
          this.$notify.error({
            title: "提示",
            message: "数量最多60个。"
          });
          return false;
        }
        this.showTitle = "添加专题页";
        this.query = {
          id: "",
          name: "",
          url: "",
          ids: "",
          imageUrl: "",
          isEnable: true,
          keywords: "",
          imageType: 5,
          jxUrl: "",
          description: "",
          floorPageList: [
            { id: "", title: "", ids: "", url: "" },
          ]
        };
      }
    },

    // 保存编辑
    async saveEdit () {

      // this.query.ids.replace(/，/, ",");// 所有中文逗号替换为英文逗号
      this.query.imageType = Number(this.query.imageType);
      let msg = this.type == "add" ? "添加" : "修改";
      if (!this.query.name) {
        this.$notify.error({
          title: "提示",
          message: "请填写专题名称"
        });
        return;
      } else if (this.query.name.length > 20) {
        this.$notify.error({
          title: "提示",
          message: "文字长度不能大于20"
        });
      }
      if (!this.query.imageUrl) {
        this.$notify.error({
          title: "提示",
          message: "请上传图片"
        });
        return;
      }
      if (!this.query.ids) {
        this.$notify.error({
          title: "提示",
          message: "请填写专题页PPT的ID"
        });
        return;
      }
      if (/.*[\u4e00-\u9fa5]+.*$/.test(this.query.ids)) {
        this.$notify.error({
          title: "提示",
          message: "专题页PPT的ID不能含有中文"
        });
        return;
      }
      console.log(!/^[\u4e00-\u9fa5]/.test(this.query.ids), "中");
      if (/^[a-zA-Z]/.test(this.query.ids)) {
        this.$notify.error({
          title: "提示",
          message: "专题页PPT的ID不能含有字母"
        });
        return;
      }

      this.query.ids = this.query.ids.replace(/，/ig, ',');
      console.log(this.query);
      if (this.query.keywords != null) {
        this.query.keywords = this.query.keywords.replace(/，/ig, ',');
        let list = this.query.keywords.split(",");
        let len = 0;
        list.map(item => {
          len += item.length;
        })
        console.log(len);
        if (len > 100) {
          this.$notify.error({
            title: "提示",
            message: "SEO关键字数量不超过100个"
          });
          return;
        }
      }

      let idsList = this.query.ids.split(",");
      if (idsList.length > 100) {
        this.$notify.error({
          title: "提示",
          message: "专题页PPT的ID数量不超过100个"
        });
        return;
      }

      if (this.query.imageType == 7 || this.query.imageType == 8) {

        if (this.query.floorPageList.length > 0) {
          let titleLen = this.query.floorPageList.filter(item => {
            return item.title.length <= 0
          })
          if (titleLen.length > 0) {
            this.$notify.error({
              title: "提示",
              message: "请输入楼层标题"
            });
            return;
          }
          let idsLen = this.query.floorPageList.filter(item => {
            return item.ids.length <= 0
          })
          if (idsLen.length > 0) {
            this.$notify.error({
              title: "提示",
              message: "请输入楼层id"
            });
            return;

          }
          let urlLen = this.query.floorPageList.filter(item => {
            return item.url.length <= 0
          })
          if (urlLen.length > 0) {
            this.$notify.error({
              title: "提示",
              message: "请输入楼层链接"
            });
            return;

          }

        }
      }

      // if (!this.query.url) {
      //   this.$notify.error({
      //     title: "提示",
      //     message: "请填写专题页的链接地址"
      //   });
      //   return;
      // }
      if (!this.handelTag) return;
      if (this.type == "add") {
        // 新增
      } else {
        // 修改
      }
      delete this.query.domain;
      delete this.query.path;
      delete this.query.updateTime;

      try {
        this.handelTag = false;
        console.log("this.query", this.query);
        let result = await getTopPicAdd(this.query);
        console.log(result);
        if (result.code == 0) {
          console.log(1);
          this.$notify({
            title: "提示",
            message: `${msg}成功`,
            type: "success"
          });
          setTimeout(() => {
            this.handelTag = true;
            this.editVisible = false;
            if (this.type == "add") {
              //添加类型重新请求数据
              this.getData();
            } else {
              //编辑
              this.bannerData[this.idx] = this.query;
              console.log("this.bannerData", this.bannerData);
              console.log(" 修改");
              this.getData();

            }
          }, 1000);
        } else {
          this.handelTag = true;
          this.$notify.error({
            title: "提示",
            message: result.message
          });
        }
      } catch (error) {
        console.log('err', error);
        this.handelTag = true;
        this.$notify.error({
          title: "提示",
          message: `${msg}失败`
        });
      }
    },
    // 分页导航
    handlePageChange (val) {
      this.pageIndex = val;
      // this.$set(this.query, "pageIndex", val);
      this.getData();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.banner-url {
  width: 100%;
  height: 100%;
}
.el-tag {
  cursor: pointer;
}
.mt10 {
  margin-top: 10px;
}
</style>
