<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> ppt文档管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <span>标题:</span>
        <el-input v-model="name"
                  placeholder="请输入标题或ID"
                  class="handle-input mr10 w100"></el-input>

        <span> 文档类型:</span>
        <el-select v-model="documentType"
                   @change="changeLabel"
                   placeholder="请选择"
                   class="handle-select mr10 w80">
          <el-option key="0"
                     label="全部"
                     :value="parseInt(0)"></el-option>
          <el-option key="1"
                     label="PPT"
                     :value="parseInt(1)"></el-option>
          <el-option key="2"
                     label="Word"
                     :value="parseInt(2)"></el-option>
          <el-option key="3"
                     label="Excel"
                     :value="parseInt(3)"></el-option>
          <el-option key="4"
                     label="素材"
                     :value="parseInt(4)"></el-option>
        </el-select>

        <span> 是否启用:</span>
        <el-select v-model="isEnable"
                   placeholder="请选择"
                   class="handle-select mr10 w80">
          <el-option key=""
                     label="请选择"
                     value=""></el-option>
          <el-option key="1"
                     label="启用"
                     value="1"></el-option>
          <el-option key="0"
                     label="禁用"
                     value="0"></el-option>
        </el-select>
        <span>文档分类（父类）:</span>
        <el-select v-model="categoryId"
                   @change="fatherChange"
                   placeholder="请选择"
                   class="handle-select mr10 ">
          <el-option key=""
                     label="请选择"
                     value=""></el-option>
          <el-option :key="item.id"
                     v-for="(item) in fatherList "
                     :label="item.name"
                     :value="item.id"></el-option>

        </el-select>
        <span>子类:</span>
        <el-select v-model="SonId"
                   placeholder="请选择"
                   class="handle-select mr10 ">
          <el-option key="0"
                     label="请选择"
                     value=""></el-option>
          <el-option :key="item.id"
                     v-for="(item) in sonList "
                     :label="item.name"
                     :value="item.id"></el-option>
        </el-select>
        <span>更新时间</span>

        <el-date-picker v-model="value2"
                        style="width: 240px"
                        :clearable="false"
                        :default-value="defaultDate"
                        @change="changeTimer"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        type="daterange"
                        align="right"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :shortcuts="shortcuts">
          <!-- unlink-panels -->
        </el-date-picker>
        <el-button type="primary ml10"
                   icon="el-icon-search"
                   @click="resetList">搜索</el-button>

      </div>
      <div class="btns">
        <el-button type="primary ml10"
                   icon="el-icon-plus"
                   @click="getAddDoc(0,0,0)">添加文章</el-button>
        <!-- <el-button type="primary ml10"
                   icon="el-icon-delete">批量删除</el-button> -->
        <el-button type="primary ml10"
                   icon="el-icon-circle-check"
                   @click="batchChange(true)">批量启用</el-button>
        <el-button type="primary ml10"
                   icon="el-icon-circle-close"
                   @click="batchChange(false)">批量禁用</el-button>

      </div>
      <el-table :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection"
                         width="55"
                         align="center"></el-table-column>
        <el-table-column prop="id"
                         label="文档Id"
                         width="120"
                         align="center"></el-table-column>
        <el-table-column prop="name"
                         label="标题"
                         align="center"></el-table-column>

        <el-table-column prop="documentType"
                         label="文档类型"
                         align="center">
          <template #default="scope">
            <span v-if="scope.row.documentType === 1">PPT</span>
            <span v-if="scope.row.documentType === 2">Word</span>
            <span v-if="scope.row.documentType === 3">Excel</span>
            <span v-if="scope.row.documentType === 4">素材</span>
          </template>

        </el-table-column>
        <el-table-column prop="categoryName"
                         label="文档分类"
                         align="center"></el-table-column>
        <el-table-column prop="updateTime"
                         label="更新时间"
                         align="center">
          <template v-slot="scope">
            <span> {{  changeDate(scope.row.updateTime )}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="downloadInitCount"
                         label="下载次数"
                         align="center"></el-table-column>
        <el-table-column prop="collectInitCount"
                         label="收藏次数"
                         align="center"></el-table-column>
        <el-table-column label="操作"
                         align="center">
          <template #default="scope">
            <el-button type="text"
                       icon="el-icon-edit"
                       @click="getChangeDoc(scope.$index, scope.row,1)">修改</el-button>
            <el-button type="text"
                       icon="el-icon-success"
                       v-if="scope.row.enable == false"
                       @click="onlyChange(true, scope.row)">启用</el-button>
            <el-button type="text"
                       icon="el-icon-error"
                       class="red"
                       v-if="scope.row.enable == true"
                       @click="onlyChange(false, scope.row)">禁用</el-button>
            <!-- <el-button type="text"
                       icon="el-icon-delete"
                       class="red"
                       @click="delRow(scope.$index, scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 不要分页 -->
      <div class="pagination">
        <el-pagination background
                       layout="total, prev, pager, next"
                       :current-page="pageIndex"
                       :page-size="10"
                       :total="pageTotal"
                       @current-change="handlePageChange"></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="添加"
               v-model="editVisible"
               width="30%">
      <el-form ref="form"
               :model="form"
               label-width="70px">
        <el-form-item label="排序">
          <el-input v-model="form.id"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload action="#"
                     list-type="picture-card"
                     :auto-upload="false">
            <template #default>
              <i class="el-icon-plus"></i>
            </template>
            <template #file="{file}">
              <div>
                <img class="el-upload-list__item-thumbnail"
                     :src="file.url"
                     alt="" />
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)">
                    <i class="el-icon-download"></i>
                  </span>
                  <span v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </template>
          </el-upload>
          <el-dialog v-model="dialogVisible">
            <img width="100%"
                 :src="dialogImageUrl"
                 alt="">
          </el-dialog>
        </el-form-item>
        <el-form-item label="链接">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="位置">
          <el-select v-model="query.address"
                     placeholder="位置"
                     class="handle-select mr10">
            <el-option key="1"
                       label="首页轮播图"
                       value="home"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="saveEdit">保 存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { fetchData, docManageList, changeMoreStatus, changeOnlyStatus, getFatherCategory, getSonCategory, getDocType } from "../../api/index";
export default {
  name: "basetable",
  data () {
    return {
      fatherList: [],// 父类
      sonList: [], // 子类
      pageIndex: 1, // 页码
      query: {
        address: "",
        name: "",
        pageIndex: 1,
        pageSize: 10
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      shortcuts: [{
        text: '最近一周',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        })(),
      }, {
        text: '最近一个月',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        })(),
      }, {
        text: '最近三个月',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        })(),
      }],
      value1: [],
      value2: [],
      defaultDate: [],
      sDate: '', // 开始时间
      eDate: '', // 结束时间
      isEnable: '', // 1启用 0禁用
      categoryId: '', // 分类id
      SonId: '', // 子类id
      name: '', //
      ids: [], // 选中id
      documentType: 0,
    };
  },
  created () {
    this.getLabels(0);
    //当前设定的日期时间
    let d = new Date
    let year1, month1, day1;
    [year1, month1, day1] = [d.getFullYear(), d.getMonth() - 2, d.getDate()]
    let sDate = year1 + '-' + month1 + '-' + day1;
    let date1 = new Date(year1, month1, day1);
    this.value1.push(date1)
    //前一天设定的日期时间
    let year2, month2, day2
    d.setTime(d.getTime() - 24 * 60 * 60 * 1000);
    [year2, month2, day2] = [d.getFullYear(), d.getMonth() + 1, d.getDate()]
    let eDate = year2 + '-' + month2 + '-' + day2;
    let date2 = new Date(year2, month2, day2);
    this.value1.unshift(date2);
    console.log(this.value2);
    this.defaultDate = new Date();
    // this.defaultDate.setMonth(new Date().getMonth() - 1);
    console.log(sDate, eDate);
    // this.sDate = sDate;
    // this.eDate = eDate;
    this.getList();
  },
  methods: {
    changeLabel () {
      this.getLabels(0);
      this.SonId = "";
      this.categoryId = "";
    },

    // 获取文档分类
    getLabels (id) {
      let params = {
        parentId: id,
        documentType: this.documentType || 1
      };
      getDocType(params).then(res => {
        console.log(res);
        if (id === 0) {
          this.fatherList = res.data;
        } else {
          this.sonList = res.data;
        }
      })
    },

    changeDate (date) {
      var dateee = new Date(date).toJSON();
      var activeDate = new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
      return activeDate
    },
    fatherChange (v) {
      this.sonList = [];
      this.SonId = "";
      this.getLabels(v);
    },
    // 获取父类
    getFatherCate () {
      getFatherCategory().then(res => {
        // this.fatherList = res.data;
        console.log(res);
      })

    },
    // 获取子类
    getSonCate (id) {

      getSonCategory(id).then(res => {
        this.sonList = res.data;
      })
    },
    changeTimer (v) {
      //  格式化时间
      const d1 = new Date(v[0]);
      const d2 = new Date(v[1]);
      const resDate = d1.getFullYear() + '-' + this.p((d1.getMonth() + 1)) + '-' + this.p(d1.getDate());
      const eDate = d2.getFullYear() + '-' + this.p((d2.getMonth() + 1)) + '-' + this.p(d2.getDate());
      this.sDate = resDate;
      this.eDate = eDate;
      console.log(resDate, eDate);
    },
    p (s) {
      return s < 10 ? '0' + s : s
    },
    resetList () {
      this.pageIndex = 1;
      this.getList();
    },
    //获取列表
    getList () {
      let cateId = this.SonId ? this.SonId : this.categoryId;
      console.log(this.isEnable, "isEnable");
      let isEnable = '';
      if (this.isEnable == '1') {
        isEnable = true;
      } else if (this.isEnable == '0') {
        isEnable = false;
      } else {
        isEnable = '';
      }
      let params = {
        name: this.name,
        isEnable: isEnable,
        categoryId: cateId,
        pageIndex: this.pageIndex,
        pageSize: 10,
        dateBeg: this.sDate,
        dateEnd: this.eDate,
        documentType: this.documentType,
      };
      docManageList(params).then(res => {
        console.log(res);
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;// 总数
      });
      // this.tableData = [
      //   {
      //     "id": 1,
      //     "name": "灰色工作总结商务汇报项目展示商务计划PPT模板",
      //     "categoryName": "产品发布",
      //     "updateTime": "1899-12-31T16:00:00.000+0000",
      //     "downloadInitCount": 1032,
      //     "collectInitCount": 1209,
      //     enable: false,
      //   }
      // ]

    },
    // 添加、修改数据
    getAddDoc (index, row) {
      let num = Math.floor(Math.random() * (100 - 1)) + 1;
      this.$router.push({
        name: "adddoc",
        query: {
          id: row.id,
          flag: num
        },
        params: {

        }
      })

    },
    getChangeDoc (index, row, flag) {
      console.log(index, row, flag);
      let url = window.location.origin + '/changedoc?id=' + row.id;
      console.log(url);
      window.open(url, '_blank');
    },
    //禁用
    DisableRow () {
      this.$confirm('将禁用该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '禁用成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消禁用'
        });
      });
    },
    // 启用
    enableRow () {
      this.$confirm('将启用该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '启用成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消启用'
        });
      });
    },
    //删除
    delRow () {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },


    handleRemove (file) {
      console.log(file);
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload (file) {
      console.log(file);
    },
    // 获取 easy-mock 的模拟数据
    getData () {
      fetchData(this.query).then(res => {
        console.log(res);
        this.tableData = res.list;
        // this.pageTotal = res.pageTotal || 50;
      });
    },
    // 触发搜索按钮
    handleSearch () {
      this.$set(this.query, "pageIndex", 1);
      this.getData();
    },
    // 删除操作
    handleDelete (index) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          this.$message.success("删除成功");
          this.tableData.splice(index, 1);
        })
        .catch(() => { });
    },
    // 多选校验
    checkIds () {
      if (this.ids.length <= 0) {
        this.$message({
          message: '未选中数据',
          type: 'error'
        })
        return false;
      }
    },
    // 多选操作
    handleSelectionChange (val) {
      this.ids = val.map(item => {
        return item.id
      })
      console.log(this.ids);
    },
    delAllSelection () {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit (index, row) {
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    // 保存编辑
    saveEdit () {
      this.editVisible = false;
      this.$message.success(`修改第 ${this.idx + 1} 行成功`);
      this.$set(this.tableData, this.idx, this.form);
    },
    // 分页导航
    handlePageChange (val) {
      console.log(val, "valval");
      this.pageIndex = val;
      this.getList();
    },
    // 批量禁用启用
    batchChange (val) {
      this.checkIds();
      if (this.ids.length <= 0) {
        return false;
      }
      let params = {
        ids: this.ids,
        enable: val
      }
      changeMoreStatus(params).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: val ? "启用成功！" : "禁用成功！",
            type: "success"
          });
          this.getList();
        } else {
          this.$message({
            message: res.message,
            type: "error"
          })

        }
      })
    },
    // 单个启用禁用
    onlyChange (val, row) {
      let txt = val ? "启用" : "禁用";
      this.$confirm('将' + txt + '该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: row.id,
          enable: val,
        };
        changeOnlyStatus(params).then(res => {
          if (res.code == 0) {
            this.$message({
              message: val ? "启用成功！" : "禁用成功！",
              type: "success"
            });
            this.getList();
          } else {
            this.$message({
              message: res.message,
              type: "error"
            })
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消' + txt
        });
      });
    }
  }
}
</script>

<style scoped lang='scss'>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.w100 {
  width: 140px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.w80 {
  width: 98px;
}
.ml10 {
  margin-left: 10px;
}
.btns {
  margin-bottom: 10px;
}
</style>
